import axios from 'axios';
import moment from 'moment';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import ApprovalPurchaseButton from 'components/ApprovalPurchaseButton';
import {Button, Checkbox, Popup, SwitchCheckbox} from '@partssourceinc/react-ui-core';
import BuyingOptionsDialog from 'components/BuyingOptionsDialog';
import {GridPagination, MobileView} from '@partssourceinc/react-ui-core';
import PDFButton from 'components/PDFButton';
import {ProductImage} from '@partssourceinc/react-ui-core';
import RejectApprovalDialog from 'components/RejectApprovalDialog';
import SubmitPoForm from 'components/SubmitPoForm';
import TrackingNumberEntryDialog from 'components/TrackingNumberEntryDialog';
import TrackingPanel from 'components/TrackingPanel';
import LoanerAcceptanceDialog from 'components/LoanerAcceptanceDialog';
import InitiateReturnModal from 'components/InitiateReturnModal';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import * as DashboardStore from 'stores/Dashboard';
import {arrayMoveMultiGrid, classNames, logEvent, logEventVerbose, formatMoney, getLocationQuery, getCookie, getTrackingNumbersUrlsData, getTrackingUrl, getFormattedPhoneNumber, getPODLetter, getFSRDocument} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import {Lg, PhonePortrait, Default, PhoneLandscape} from 'responsive';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import $ from 'jquery';
import 'less/grid/DataTable.less';
import 'less/grid/grid.less';
import 'less/grid/MultiSelect.less';
import 'less/grid/primereact.less';
import 'less/grid/theme.less';
import 'less/multiGrid.less';
import {FormularyCard, FormularyFacets, ProFormaSavingsBanner, FormularyPendingChanges, FormularyFacetsModal} from '../pages/VisualFormulary';
import {setCookie, getPersonalizationCookiesEnabled, downloadCreditMemos} from '../utility';
import {RETURN_CONFIRMATION_MSG} from '../enums';

export const CardActionType = {
    Quote: 'Quote',
    AwaitingPO: 'Awaiting PO',
    AwaitingApproval: 'AwaitingApproval',
    AwaitingApprovalReadOnly: 'AwaitingApprovalReadOnly',
    Delivered: 'Delivered',
    OrderHistory: 'OrderHistory',
    Exchange: 'Exchange',
    ServiceEvent: 'ServiceEvent',
    Return: 'Return',
    Shipped: 'Shipped',
    Repair: 'Repair',
    AltOptions: 'AltOptions',
    LoanerQuoted: 'LoanerQuoted',
    LoanerReturn: 'LoanerReturn',
};

export const RequesterViewType = {
    AllRequesters: 'AllRequesters',
    CurrentUser: 'CurrentUser',
};

const ViewType = {
    Grid: 'Grid',
    Card: 'Card',
};

@withRouter
@connect((state) => ({user: state.user, dashboard: state.dashboard, sessionStorageAvailable: state.network.sessionStorageAvailable, network: state.network}), Object.assign({}, UserStore.actionCreators, DashboardStore.actionCreators, NetworkStore.actionCreators))
export class MultiGrid extends Component {
    static displayName = 'MultiGrid';
    static propTypes = {
        // Needs to match the default columns property in the user store
        gridName: PropTypes.string.isRequired,
        gridKeys: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.string,
                fieldName: PropTypes.string,
                value: PropTypes.string,
                tabDisplay: PropTypes.string,
                // cardAction: PropTypes.oneOf(Object.keys.CardActionType),
                height: PropTypes.number,
            })
        ),
        data: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        subLabel: PropTypes.string.isRequired,
        customEmptyMessage: PropTypes.string,
        onSelect: PropTypes.func,
        onTabClick: PropTypes.func,
        onHandleBundleCandidates: PropTypes.func,
        disableCardView: PropTypes.bool,
        headerLink: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
        }),
        cardAction: PropTypes.oneOf(Object.values(CardActionType)),
        showCheckbox: PropTypes.bool,
        selectionMode: PropTypes.oneOf(['single', 'multiple', 'checkbox']),
        selectionKeyField: PropTypes.string,
        onRefresh: PropTypes.func,
        loadingData: PropTypes.bool,
        hideLegend: PropTypes.bool,
        gridEntityName: PropTypes.string,
        showShipInfo: PropTypes.bool,
        height: PropTypes.number,
        showRequesterToggle: PropTypes.bool,
        pageSize: PropTypes.number,
        onRequesterToggle: PropTypes.func,
        requesterViewType: PropTypes.oneOf(Object.values(RequesterViewType)),
        user: UserStore.StateShape,
        dashboard: DashboardStore.StateShape,
        ...DashboardStore.ActionShape,
        ...UserStore.ActionShape,
        ...ReactRouterPropTypes,
    };

    static defaultProps = {
        gridKeys: [],
        selectionMode: 'single',
        showRequesterToggle: false,
        requesterViewType: RequesterViewType.CurrentUser,
        customEmptyMessage: null,
    };

    constructor(props) {
        super(props);

        const {location} = props;

        this.state = {
            currentTab: null,
            tabCounts: [],
            view: props.defaultToCardView || props.disableCardView ? ViewType.Grid : ViewType.Card,
            checkboxItems: [],
            showBuyingOptions: false,
            buyingOptions: [],
            buyingOptionsImage: '',
            showRejectDialog: false,
            showSubmitPoDialog: false,
            rejectItems: {},
            notification: '',
            purchaseLines: [],
            showColumnSelection: false,
            columnSelectionColumns: [],
            columns: _.sortBy(props.user[props.gridName], 'order'),
            requesterViewType: props.requesterViewType || RequesterViewType.CurrentUser,
            showCheckInDialog: false,
            showReturnDialog: false,
            selectedLineItem: {},
            showTrackingNumberDialog: false,
            gridEntityName: props.gridEntityName || 'orders',
            showNudgeConfirmDialog: false,
            showTrackingHistory: false,
            showMissingFileModal: false,
            missingFileMsg: '',
            currentPage: 1,
            selectedPageSize: props.pageSize ? props.pageSize : 10,
            defaultTab: true,
            renderGridMobile: false,
            onOpsV2: location.pathname.toLowerCase() === '/command-center' ? true : false,
            tooltipLineItemId: -1,
            tooltipLocation: {},
            showFormularyFacetsModal: false, 
            showLoanerAcceptanceDialog: false,
            loanerItem: null,
            showAllFsrDocuments: {},
            showConfirmation: false,
        };

        this.columnTemplate = this.columnTemplate.bind(this);
        this.sendLogTrackShipping = this.sendLogTrackShipping.bind(this);
        this.renderCheckboxColumn = this.renderCheckboxColumn.bind(this);
    }

    componentDidMount() {
        this.initializeTabs(this.props);

        const {subLabel, sessionStorageAvailable} = this.props;
        if (sessionStorageAvailable)
            sessionStorage.prevGrid = subLabel;
    }

    componentWillReceiveProps(props) {
        const {columns: currentColumns, data} = this.props;
        const {columns: stateColumns, currentPage, selectedPageSize} = this.state;

        this.setTabCounts(props);

        if ((currentColumns || []).length === 0 && (props.columns || []).length > 0) {
            const columns = [
                ...stateColumns
                    .concat(props.columns)
                    .reduce((m, o) => m.set(o.field, Object.assign(m.get(o.field) || {}, o)), new Map())
                    .values(),
            ];

            this.setState({columns: _.sortBy(columns, 'order'), view: props.view});
        }

        if (data && data.length > 0 && (currentPage * selectedPageSize) > data.length && currentPage != 1)
            this.setState({currentPage: 1});
    }

    setTabCounts = (props) => {
        const {gridKeys} = props;
        let tabCounts = [];

        for (let i = 0; i < gridKeys.length; i++) {
            let key = gridKeys[i];
            tabCounts.push({id: key.id, count: this.getBadgeCount(key, props)});
        }

        this.setState({tabCounts});
        return tabCounts;
    }

    initializeTabs = (props) => {
        const id_ins = getCookie('id_ins');
        const {location} = this.props;
        const tabCounts = this.setTabCounts(props);
        const urlParams = getLocationQuery(location);
        const currentTab = (urlParams && urlParams.tab) || (tabCounts.length && tabCounts[0].id) || null;
        this.setState({currentTab: currentTab, id_ins: id_ins});

        if (currentTab && !urlParams.tab) {
            let url = location.search ? `${location.pathname}${location.search}&` : `${location.pathname}?`;
            window.history.replaceState(null, null, `${url}tab=${currentTab}`);
        }
    }

    tabClick = (tabInd) => {
        this.setState({currentTab: tabInd, currentPage: 1, defaultTab: false, renderGridMobile: true});
        this.sendLogTabClick(tabInd, false);

        const {location, isVisualFormulary, onTabClick} = this.props;
        let url = `${location.pathname}?tab=${tabInd}`;

        if (isVisualFormulary) {        
            const {gridKeys} = this.props;
            const currentTabName = gridKeys.find((x) => x.id === tabInd).tabDisplay;
            axios.post(`/FormularyService/api/v1/log/99/Visual Formulary`, [{name: 'VF Tab', value: currentTabName}]);        
        }

        window.history.pushState(null, null, url);

        if (typeof(onTabClick) === 'function') {
            onTabClick(tabInd);
        }
    }

    onSearchFocus = () => {
        this.setState({showSearchBox: true});
    }

    onSearchBlur = (e) => {
        this.setState({showSearchBox: e.target.value !== ''});
        const {searchText, currentTab, id_ins} = this.state;
        const {location} = this.props;
        if (searchText != undefined && searchText != '') {
            const tabCounts = this.getGridItems();
            logEvent('My Account Search', {
                'Search Phrase': searchText,
                'Search results': tabCounts.length,
                Page: location.pathname,
                Tab: currentTab,
            });
        }
    }

    onSearch = (e) => {
        this.setState({searchText: e.target.value.toLowerCase(), currentPage: 1});
    }

    renderTabStrip = () => {
        const {currentTab} = this.state;
        const {gridKeys} = this.props;

        return gridKeys.length ? (
            <>
                <div className="ps-hidden-lg ps-hidden-xl tab-strip">
                    {gridKeys.map((x, i) => (
                        <div className={currentTab === x.id ? 'active' : ''} onClick={() => this.tabClick(x.id)} key={i}>
                            <div className="tab-title">
                                {x.tabDisplay} <span>{this.getBadgeCount(x).toLocaleString()}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md grid-tab-wrapper full-row">
                    {gridKeys.map((x, i) => (
                        <label className={currentTab === x.id ? 'checked' : ''} onClick={() => this.tabClick(x.id)} key={i}>
                            {x.tabDisplay} <span>{this.getBadgeCount(x).toLocaleString()}</span>
                        </label>
                    ))}
                </div>
            </>
        ) : (
            <Lg><div className="grid-tab-wrapper empty" /></Lg>
        );
    };

    renderSelectionBar = (data) => {
        const {checkboxItems, view} = this.state;
        const {
            cardAction,
            user: {facilities},
            isVisualFormulary,
        } = this.props;

        if (view === ViewType.Card || isVisualFormulary) return null;

        if (cardAction === CardActionType.AwaitingApproval) {
            let totalSumChecked = 0;
            let needsApproval = false;

            if (checkboxItems.length > 0 && cardAction === CardActionType.AwaitingApproval) {
                totalSumChecked = _.sumBy(checkboxItems, (x) => {
                    return parseFloat(x.extendedPrice);
                });
                needsApproval = totalSumChecked > facilities[0].purchaseLimit;
            }

            const disableApproval = checkboxItems.length > 0 && checkboxItems.some(o => o.options?.some(op => op.moq > op.quantity));

            return (
                <React.Fragment>
                    {checkboxItems.length > 0 && (
                        <div className="selection-bar">
                            <span>
                                ({checkboxItems.length}) {checkboxItems.length === 1 ? 'item' : 'items'} selected totaling to {formatMoney(_.sumBy(checkboxItems, (x) => parseFloat(x.extendedPrice)))}
                            </span>
                            <span className="buttons">
                                {needsApproval ? (
                                    <span
                                        className={`confirm ${disableApproval ? 'disabled' : ''}`}
                                        onClick={() => !disableApproval ? this.onApprove(null, true) : ''}>
                                        Approve
                                    </span>
                                ) : (
                                    <span className={`confirm ${disableApproval ? 'disabled' : ''}`}>
                                        <ApprovalPurchaseButton lineItemIds={checkboxItems.map((x) => x.lineItemId)} showLink={true} isDisabled={disableApproval} />
                                    </span>
                                )}
                                <span className="deny" onClick={() => this.onShowRejectItemsDialog()}>
                                    Reject
                                </span>
                            </span>
                        </div>
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {checkboxItems.length > 0 && (
                        <div className="selection-bar">
                            <span>{`(${checkboxItems.length}) ${checkboxItems.length === 1 ? 'item' : 'items'} selected`}</span>
                        </div>
                    )}
                </React.Fragment>
            );
        }
    }

    renderMOQBar = (data) => {
        const {
            cardAction,
        } = this.props;

        const disableApproval = data && data.length > 0 && data.some(o => o.options?.some(op => op.moq > op.quantity));

        if (cardAction === CardActionType.AwaitingApproval && disableApproval) {
            return (
                <div className="note note--warning">
                    <p className="lbl-info">One or more of your items has a min. order quantity</p>
                </div>
            );
        } else {
            return null;
        }
    }

    onApprove = (item, approveAll) => {
        const {checkboxItems} = this.state;
        const {
            user: {facilities},
            onRefresh,
        } = this.props;

        let request;

        if (approveAll) {
            const facility = facilities.find((x) => Number(x.facilityId) === Number(checkboxItems[0].facilityId));
            request = {
                groupId: facility.groupId,
                companyId: facility.facilityId,
                lineItemIds: checkboxItems.map((x) => x.lineItemId),
            };
        } else {
            const facility = facilities.find((x) => Number(x.facilityId) === Number(item.facilityId));
            request = {
                groupId: facility.groupId,
                companyId: item.facilityId,
                lineItemIds: [item.lineItemId],
            };
        }

        this.setState({saving: true});

        axios.post('/ShoppingService/api/v1/approval/approve', request).then(() => {
            if (approveAll) {
                let notification = checkboxItems.length === 1 ? `(${checkboxItems.length}) item submitted for approval` : `(${checkboxItems.length}) items submitted for approval`;
                this.setState({notification, checkboxItems: []});
            }
            onRefresh();
        });
    }

    onNudgeApprover = (item, isHoldPo) => {
        const {
            user: {facilities},
        } = this.props;
        const {id_ins} = this.state;
        let request;
        const facility = _.find(facilities, (x) => x.facilityName === item.facility);
        request = {
            groupId: facility.groupId,
            companyId: item.facilityId,
            lineItemIds: [item.lineItemId],
            isPoHold: isHoldPo,
        };
        axios.post('/ShoppingService/api/v1/orders/sendNudge', request).then(() => {
            this.setState({showNudgeConfirmDialog: true, selectedLineItem: item});
        });

        let currdate = new Date();
        let createdate = moment(item.created).toDate();

        logEvent('Nudge Approver', {
            'Nudge Approver': item.lineItemId,
            'Ship Priority': item.carrierPriority,
            Age: `${parseInt((currdate - createdate) / (1000 * 60 * 60 * 24))}day`,
            'Time to Cutoff': item.shipCutoffUtc ? `${parseInt((moment(item.shipCutoffUtc).toDate() - currdate) / (1000 * 60) / 60)}hr` : '',
        });
    }

    truncateDescriptionText = (desc) => {
        if (!desc) return desc;

        if (desc.length < 80) {
            return desc;
        } else {
            return desc.substring(0, 80) + ' ...';
        }
    }

    renderShippingForService = (data) => {
        const {
            user: {settings},
        } = this.props;

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

        const status = data.status;
        let text = '';
        let scheduledText = 'Scheduled Completion PENDING';
        let date = '';

        switch (status) {
            case 'Initiated':
                text = data.serviceInfo && data.serviceInfo.ticketId !== 0 ? data.serviceInfo.serviceAppointmentStatus : 'Awaiting Quote';
                break;
            case 'Open Service':
                text = data.serviceInfo ? data.serviceInfo.serviceAppointmentStatus : '';
                if (data.serviceInfo && data.serviceInfo.arrivalTime) {
                    scheduledText = `Service Specialist Arrival`;
                    date = moment.utc(data.serviceInfo.arrivalTime, 'YYYY-MM-DD-h.mm.ss').local().format('MM/DD/YY h:mm A');
                } else scheduledText = 'Service Specialist Arrival - Pending';
                break;
            case 'Quoted':
                text = 'For questions or to cancel this quote, call ';
                break;
            case 'Awaiting PO':
                text = 'Awaiting PO';
                break;
            case 'Awaiting Approval':
                text = 'Awaiting Approval';
                break;
        }

        if (status === 'Quoted') {
            return (
                <div className="col-md-12" style={{height: '42px'}}>
                    <strong style={{fontSize: 16}}>{text}<a style={{textDecoration: 'underline'}} href={'tel:+1' + defaultPhoneNumber}>{dedicatedPhoneNumber}</a></strong>
                </div>
            );
        } else {
            return (
                <ul className="StepProgress">
                    <li className={'StepProgress-item is-next'}>
                        <div className="eta-info">
                            {scheduledText} <strong>{date}</strong>
                        </div>
                    </li>
                    <li className="StepProgress-item is-done current">
                        <div className="order-status">
                            <div className="status">{text || 'Open Service Event'}</div>
                        </div>
                    </li>
                </ul>
            );
        }
    };

    acceptLoanerDialog = (data) => {
        this.setState({showLoanerAcceptanceDialog: true, loanerItem: data});
    }

    loanerAccepted = () => {
        const {loanerItem, requesterViewType} = this.state;
        const {onRefresh} = this.props;

        axios.post(`/ShoppingService/api/v1/orders/loaner/accept/${loanerItem.lineItemId}`).then(() => {
            this.setState({showLoanerAcceptanceDialog: false, loanerItem: null});
            if (onRefresh) onRefresh(requesterViewType);
        });
    }

    rejectLoaner = (data) => {
        const {requesterViewType} = this.state;
        const {onRefresh} = this.props;

        axios.post(`/ShoppingService/api/v1/orders/loaner/reject/${data.lineItemId}`).then(() => {
            this.setState({showLoanerAcceptanceDialog: false});
            if (onRefresh) onRefresh(requesterViewType);
        });
    }

    renderShipSectionForLoaners = (data, cardActionType) => {
        const {currentTab} = this.state;

        if (data.groupName === 'OnLoan') {
            if (data.repairStatus === 'Repairing')
                return null
            
            if (data.repairStatus === 'Delivered' || data.repairStatus === 'Complete')
                return this.renderLoanerRgaSection(data, cardActionType);
        }

        if (data.groupName === 'Requested')   
            return (
                <div className="card-missing-paperwork card-missing-paperwork--bottom">
                    <div className="card-missing-paperwork-title">Loaner Requested</div>
                    <div>Loaners availability can take up to 24 hours to confirm. One your requested loaner is confirmed you must accept you loaner and our <a href="/policies/termsandconditionofsale">Terms and Conditions</a></div>
                </div>
            );
        
        if (data.groupName === 'Quoted')  
            if (!data.loanerAcceptanceTimestamp)
                return (
                    <div className="card-missing-paperwork card-missing-paperwork--bottom">
                        <div className="card-missing-paperwork-title">Accept Loaner By {moment(data.quotedDate).add(1, 'days').format('MM/DD')} </div>
                        <div className="card-missing-paperwork-title"><img style={{'width': '26px', 'margin': '5px'}} src="/images/icn_checkmark.png" /> Loaner available on {moment(data.quotedDate).format('MM/DD')} </div>
                        <div>Accept your loaner unit and our <a href="/policies/termsandconditionofsale">Terms and Conditions</a> within 24 hours. Loaners accepted beyond 24 hours can cause additional delays.</div>
                    </div>
                );
            else
                return (
                    <div className="card-missing-paperwork card-missing-paperwork--bottom">
                        <div className="card-missing-paperwork-title"><img style={{'width': '26px', 'margin': '5px'}} src="/images/icn_checkmark.png" />Your loaner is accepted on {moment(data.loanerAcceptanceTimestamp).format('MM/DD')} </div>
                    </div>
                );

        let eta = 'PENDING';

        if (data.groupName === 'ShippedInbound') {
            if (data.estimatedDelivery) {
                let date = moment(data.estimatedDelivery).format('MM/DD');
                let time = moment(data.estimatedDelivery).format('hh:mma');
                eta = `Scheduled Delivery ${date}, ${time === '12:00am' ? 'End of Day' : time}`;
            } else {
                eta = 'Scheduled Delivery Date Pending';
            }
        }

        if (data.groupName === 'ShippedOutbound') {
            if (data.customerEstimatedDelivery) {
                let date = moment(data.customerEstimatedDelivery).format('MM/DD');
                let time = moment(data.customerEstimatedDelivery).format('hh:mma');
                eta = `Scheduled Delivery ${date}, ${time === '12:00am' ? 'End of Day' : time}`;
            } else {
                eta = 'Scheduled Delivery Date Pending';
            }
        }

        let isDelivered = data.groupName === 'OnLoan' || data.groupName === 'Returned';

        let shipLine2;

        if (data.groupName === 'ShippedOutbound' || data.groupName === 'ShippedInbound' || isDelivered) {
            shipLine2 = data.shippingAddress;
        }

        if (data.groupName === 'ShippedInbound') data.status = 'Loaner Accepted';

        if (data.groupName === 'ShippedOutbound') data.status = 'Shipped';

        let shipLine3 = '';

        if (data.groupName === 'ShippedOutbound' || data.groupName === 'ShippedInbound')
            shipLine3 = data.shippingAttention;

        return (
            <React.Fragment>
                <div className="card-ship-info">
                    <ul className="StepProgress">
                        <li className={isDelivered ? 'StepProgress-item is-next complete' : 'StepProgress-item is-next'}>
                            <div className="eta-info">
                                {isDelivered ? (
                                    <div className="delivered">Delivered 
                                        <a
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            onClick={(event) => this.sendLogTrackShipping(event, data)}
                                            href={`${getTrackingUrl(data.trackingNumber, data.shipViaId)}`}
                                            style={{'margin': '5px', 'fontSize': '18px'}}
                                        >
                                            {data.trackingNumber}
                                        </a>
                                    </div>
                                ) : (
                                    <div className="eta">
                                        {' '}
                                        {data.status === 'OnLoan' || data.groupName === 'ShippedInbound' || data.groupName === 'ShippedOutbound' ? (
                                            <React.Fragment>{eta}</React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {' '}
                                                    ETA <div className="eta-date">{eta}</div>{' '}
                                            </React.Fragment>
                                        )}{' '}
                                    </div>
                                )}
                                {
                                    !isDelivered ? 
                                        <React.Fragment>
                                            {shipLine2 ? <div className="eta-desc">{shipLine2}</div> : null}
                                            <div className="eta-line-3">{shipLine3}</div>
                                        </React.Fragment>
                                        : null
                                }

                            </div>
                        </li>
                        {isDelivered ? null : (
                            <li className="StepProgress-item is-done current">
                                <div className="order-status">
                                    <div className="status">
                                        {data.status} {data.status === 'Ordered' ? moment(data.closedDate).format('MM/DD/YYYY') : ''}
                                        {data.urlsData && data.urlsData && data.urlsData.length > 0 && (
                                            <div className="tracking">
                                                        Tracking #
                                                {data.urlsData &&
                                                            data.urlsData.map((urlData, index) => {
                                                                return (
                                                                    <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}>
                                                                        {urlData.number}{' '}
                                                                    </a>
                                                                );
                                                            })}
                                            </div>
                                        )}
                                        {data.urlsData && data.urlsData.length < 1 && <div className="tracking">Tracking number unavailable</div>}
                                    </div>
                                    <div className="ship-method">{data.carrierPriority}</div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }

    shouldRenderMissingPaperWorkSection = (data) => {
        const shouldRender = (!data.rgaNumber || 
                !(!data.canGenerateShippingLabel && 
                 (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) && 
                 data.vendorTrackingNumber && 
                 data.isShipFromCustomer));

                 return shouldRender;
    };

    renderShipSection = (data, cardActionType) => {
        const {currentTab} = this.state;
        if (cardActionType === CardActionType.AltOptions) return null;

        if (cardActionType === 'Repair' && data.groupName !== 'ShippedInbound' && data.groupName !== 'Delivered') {
            if (currentTab === 'initiated' && this.shouldRenderMissingPaperWorkSection(data)) {
                return this.renderMissingPaperWorkSection(data, cardActionType)
            }
            else if (currentTab === 'initiated' && data.rgaNumber && data.dueDate) return this.renderRgaRepairSection(data, cardActionType);
            else if (currentTab === 'repairing' && data.isRepairDeclined) return this.renderNoSaleRepairSection(data)
            else return null;
        }

        if (data.groupName === 'Requested' || data.groupName === 'eQuoted' || data.groupName === 'Quoted') {
            if (data.isRepairDeclined) {
                return this.renderNoSaleRepairSection(data)
            } else {
                return this.renderQuoteSection(data);
            }
            
        }

        if (cardActionType === 'Exchange' || cardActionType === 'Return') {
            return this.renderRgaSection(data, cardActionType);
        }

        let eta = 'PENDING';

        if (data.status === 'Ordered' || data.status === 'Back Ordered') {
            eta = 'Scheduled Delivery Date Pending';
        } else if (data.status === 'Shipped' || data.groupName === 'ShippedInbound') {
            if (data.estimatedDelivery) {
                let date = moment(data.estimatedDelivery).format('MM/DD');
                let time = moment(data.estimatedDelivery).format('hh:mma');
                eta = `Scheduled Delivery ${date}, ${time === '12:00am' ? 'End of Day' : time}`;
            } else {
                eta = 'Scheduled Delivery Date Pending';
            }
        }

        let isDelivered = data.status === 'Delivered' || data.groupName === 'Delivered';
        let isReturnedUnrepaired = cardActionType === 'Repair' && (data.isRepairDeclined) && (data.groupName === 'ShippedInbound' || data.groupName === 'Delivered')

        let shipLine2;
        let ind, nind = 0;
        if (data.shippingAddress && data.shippingAddress.length > 32) {
            ind = data.shippingAddress.lastIndexOf(',');
            data.shippingAddress = data.shippingAddress.substring(0,ind) + '' + data.shippingAddress.substring(ind + 1);
            nind = data.shippingAddress.lastIndexOf(',') + 1;
        }
        if (data.status === 'Ordered' || data.status === 'Back Ordered') {
            shipLine2 = data.estimatedShipDate && data.backordered === 'Yes' ? `Estimated to ship by ${moment(data.estimatedShipDate).format('MM/DD')}` : data.shippingAddress;
        } else if (data.status === 'Shipped' || data.groupName === 'ShippedInbound' || isDelivered) {
            shipLine2 = data.shippingAddress;
        }

        if (data.groupName === 'ShippedInbound') data.status = 'Shipped Inbound';

        if (data.groupName === 'Delivered') data.status = 'Delivered';

        let shipLine3 = '';

        if (data.status === 'Ordered' || data.status === 'Back Ordered')
            if (data.prependPoToShipping) shipLine3 = data.estimatedShipDate ? '' : data.shippingAttention;
            else shipLine3 = data.estimatedShipDate ? '' : `PO# ${data.poNumber}, ${data.shippingAttention}`;
        else if ((data.status === 'Shipped' || data.groupName === 'ShippedInbound') && !data.prependPoToShipping)
            shipLine3 = data.prependPoToShipping ? data.shippingAttention : `PO# ${data.poNumber}, ${data.shippingAttention}`;
        else shipLine3 = data.shippingAttention;

        return (
            <React.Fragment>
                {data.isServiceItem && data.statusId === 8 ? null : (
                    <div>
                        {isReturnedUnrepaired ? <div className="card-exchange-return overDue">
                            <div className="card-missing-paperwork-title">Repair Quote Declined</div>
                            <div>Repair Quote has been declined. The item has been returned unrepaired.</div>
                        </div> : null}
                        <div className="card-ship-info">
                            {data.status !== 'Quoted' && data.status !== 'eQuoted' && (
                                <div className="history-link" onClick={() => this.sendLogViewHistory(data)}>
                                    View History
                                </div>
                            )}
                            {data.serviceInfo ? (
                                this.renderShippingForService(data)
                            ) : (<div>
                                <ul className="StepProgress">
                                    <li className={isDelivered ? 'StepProgress-item is-next complete' : 'StepProgress-item is-next'}>
                                        <div className="eta-info">
                                            {isDelivered ? (
                                                <div className="delivered">Delivered<br />{` ${moment(data.dateDelivered).format('MMMM DD, YYYY hh:mm a')}`}</div>
                                            ) : (
                                                <div className="eta">
                                                    {' '}
                                                    {data.status === 'Ordered' || data.status === 'Back Ordered' || data.status === 'Shipped' || data.groupName === 'ShippedInbound' ? (
                                                        <React.Fragment>{eta}</React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {' '}
                                                            ETA <div className="eta-date">{eta}</div>{' '}
                                                        </React.Fragment>
                                                    )}{' '}
                                                </div>
                                            )}
                                            {
                                                shipLine2 ? 
                                                    (nind > 0) ? 
                                                        <div className="eta-desc">{shipLine2.substring(0,nind)}<br />{shipLine2.substring(nind + 1,shipLine2.length)}</div> : 
                                                        <React.Fragment>{shipLine2}</React.Fragment>
                                                    : 
                                                    null
                                            }
                                            {isDelivered ? (
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    onClick={(event) => this.sendLogTrackShipping(event, data)}
                                                    href={`${getTrackingUrl(data.trackingNumber, data.shipViaId)}`}
                                                >
                                                    {data.trackingNumber}
                                                </a>
                                            ) : null}
                                            <div className="eta-line-3">{shipLine3}</div>
                                        </div>
                                    </li>
                                    {isDelivered ? null : (
                                        <li className="StepProgress-item is-done current">
                                            <div className="order-status">
                                                <div className="status">
                                                    {data.status} {data.status === 'Ordered' ? moment(data.closedDate).format('MM/DD/YYYY') : ''}
                                                    {data.urlsData && data.urlsData && data.urlsData.length > 0 && (
                                                        <div className="tracking">
                                                                Tracking #
                                                            {data.urlsData &&
                                                                    data.urlsData.map((urlData, index) => {
                                                                        return (
                                                                            <a target="_blank" rel="noreferrer noopener" href={`${urlData.url}`}>
                                                                                {urlData.number}{' '}
                                                                            </a>
                                                                        );
                                                                    })}
                                                        </div>
                                                    )}
                                                    {data.urlsData && data.urlsData.length < 1 && <div className="tracking">Tracking number unavailable</div>}
                                                </div>
                                                <div className="ship-method">{data.carrierPriority}</div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                                
                            )}
                        </div>
                    </div>
                    
                )}
            </React.Fragment>
        );
    }

    renderWarningSection = (data) => { 
        const {user: {settings: {isShowBackorderMessages}}} = this.props;

        if (!isShowBackorderMessages)
            return null;

        let showWarningMessage = false;
        let warrningMessage = '';
        let showFollowupDate = false;

        if (data.statusId == 25 && (data.estimatedShipDateReasonId == 2 || data.backordered == 'Yes')) { // ordered && backorder
            showWarningMessage = true;
            if (data.estimatedShipDate)
                warrningMessage = `This item is on backorder until ${moment(data.estimatedShipDate).format('MM/DD/YYYY')}`;
            else
                warrningMessage = `This item is on backorder with no estimated ship date`;

            showFollowupDate = true;
        } else if (data.statusId == 25 && (!data.trackingStatusId || data.trackingStatusId == 1) && (data.transitDays == 1 || data.transitDays == 2) && (data.estimatedShipDate && new Date(data.estimatedShipDate) > new Date().setHours(0, 0, 0, 0))) {
            showWarningMessage = true;
            warrningMessage = `This item will ship on ${moment(data.estimatedShipDate).format('MM/DD/YYYY')}`
        }

        return (
            showWarningMessage &&
            <div className="card-warrning-info">
                <div style={{fontWeight: '600'}}> <img style={{width: '20px', marginBottom: '5px', marginRight: '5px'}} src="images/icn_orange-exclamation@2x.png" />{warrningMessage}</div>
                { showFollowupDate && (<div style={{marginLeft: '26px', fontWeight: '600'}}>Follow up date: {moment(data.followupDate).format('MM/DD/YYYY')} </div>) } 
            </div>            
        )
    }

    renderQuoteSection = (data, cardActionType) => {
        const title = data.groupName === 'Requested' ? 'Quote Initiated' : 'Quote Available';
        const description = data.groupName === 'Requested' ? 'We are working on your quote.' : cardActionType === 'Repair' ? 'Item is ready for review' : 'Item is ready for purchase.';

        return (
            <div className="card-missing-paperwork card-missing-paperwork--bottom">
                <div className="card-missing-paperwork-title">{title}</div>
                <div>{description}</div>
            </div>
        );
    }

    renderRgaSection = (data, cardActionType) => {
        const rgaClasses = {
            'card-exchange-return': true,
            isDue: data.isDue,
            overDue: data.isOverDue,
        };

        return (
            <div className={classNames(rgaClasses)}>
                <div className="card-missing-paperwork-title">{`${cardActionType} Due ${moment(data.dueDate).format('MM/DD/YYYY')}`}</div>
                {data.isOverDue ? <div className="rga-past-due">PAST DUE</div> : null}
                {cardActionType == 'Return' ? (
                    <div>Returns are credited upon receipt and inspection. If you have used your own shipping label enter tracking below to avoid delays. </div>
                ) : (
                    <div>To avoid additional charges, your core exchange must be shipped back by the due date. If you have used your own shipping label enter tracking below to avoid delays.</div>
                )}
            </div>
        );
    }

    renderLoanerRgaSection = (data, cardActionType) => {
        const rgaClasses = {
            'card-exchange-return': true,
            isDue: data.isDue,
            overDue: data.isOverDue,
        };

        return (
            <div className={classNames(rgaClasses)}>
                {(data.loanerReturnDueDate) ? <div className="card-missing-paperwork-title">{`Loaner return Due ${moment(data.loanerReturnDueDate).format('MM/DD/YYYY')}`}</div> : null}
                {data.isOverDue ? <div className="rga-past-due">PAST DUE</div> : null}
                {data.isOverDue ? (
                    <div>Loaner are credited upon receipt and inspection. If you have used your own shipping label enter tracking below to avoid delays. Loaner returned past the due date are subject to replacement values charge.</div>
                ) : (
                    <div>Loaner are credited upon receipt and inspection. If you have used your own shipping label enter tracking below to avoid delays.</div>
                )}
            </div>
        );
    }

    renderNoSaleRepairSection = (data) => {
        return (
            <div className="card-exchange-return overDue">
                <div className="card-missing-paperwork-title">Repair Quote Declined</div>
                <div>Repair Quote has been declined. The unrepaired item will be returned to you shortly.</div>
            </div>
        )
    }

    renderRgaRepairSection = (data) => {
        const rgaClasses = {
            'card-missing-paperwork': true,
            'm-top-40': false,
            isDue: data.isDue,
            overDue: data.isOverDue,
        };
        const isQuoted = ['aftermarket quoted repair', 'oem quoted repair', 'n/a'].includes(data.condition?.toLowerCase())
        return (<div className={classNames(rgaClasses)}>
            <div className="card-missing-paperwork-title">{`Return Due ${moment(data.dueDate).format('MM/DD/YYYY')}`}</div>
            {(data.rgaCreatedTimestamp &&
            !data.canGenerateShippingLabel &&
            (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) &&
            (data.vendorTrackingNumber && data.isShipFromCustomer)) ? <div className="card-rga-printed"><FontAwesomeIcon icon={faCheckCircle} size="sm" />&nbsp;<span>Label Created</span> on {moment(data.rgaCreatedTimestamp).format('MM/DD/YYYY')}</div> : null}
            { data.isOverDue ? <div className="rga-past-due">PAST DUE</div> : null }
            { isQuoted ? 
                <div>In order to obtain your quote, this item must be sent to the vendor for evaluation. Please print the attached paperwork or enter in you tracking below if you used your own shipping.</div> :
                <div>To avoid cancellation ship your repair to us by the due date. If you have used your own shipping label enter tracking to the right to avoid delays.</div>
            }
        </div>)
    }

    renderMissingPaperWorkSection = (data, cardActionType) => {
        const rgaClasses = {
            'card-missing-paperwork': true,
        };
        const isQuoted = ['aftermarket quoted repair', 'oem quoted repair', 'n/a'].includes(data.condition?.toLowerCase())
        return (
            <div className={classNames(rgaClasses)}>
                <div className="card-missing-paperwork-title">Repair {isQuoted ? 'Quote' : null} Initiated</div>
                <div>{isQuoted ? 
                    'In order to obtain your quote, this item must be sent to the vendor for evaluation. Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays' :
                    'Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays' }
                </div>
            </div>
        );
    }

    renderAltSourcingSection = (data, cardActionType) => {        
        if (cardActionType !== CardActionType.AltOptions) return null;

        return (
            <div className="card-alt-option-msg">
                <div className="sec-title">Alternative Options Available</div>
                <div className="msg">Your item is ready for review.</div>
            </div>
        )
    }

    onPackingBundle = (lineItemId) => {
        const {onHandleBundleCandidates} = this.props;
        onHandleBundleCandidates(lineItemId);
    };

    renderAction(data, cardActionType) {
        const {history} = this.props;
        const {location} = window;

        switch (cardActionType) {
            case CardActionType.Quote:
                return (
                    <div className="card-action">
                        {data.isServiceItem ? <div className="action-row">
                            <Button secondary={true} onClick={() => history.push(`/service/${data.lineItemId}`, {from: location.pathname + location.search})}>CHOOSE TECHNICIANS{data.optionCount > 0 && ` (${data.optionCount})`}</Button>
                        </div> :
                            <div className="action-row">
                                {!data.isRepairDeclined ? <Button secondary={true} onClick={() => history.push(`/quotes/${data.lineItemId}`)}>SEE BUYING OPTIONS{data.optionCount && ` (${data.optionCount})`}</Button> : null}
                            </div>
                        }
                    </div>
                );
            case CardActionType.AwaitingPO:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button secondary={true} onClick={() => this.setState({showSubmitPoDialog: true, checkboxItems: [data]})}>
                                ADD P.O.#
                            </Button>
                        </div>
                    </div>
                );

            case CardActionType.AwaitingApproval: {
                const {
                    user: {facilities},
                } = this.props;
                const approvalLimit = facilities.find((x) => Number(x.facilityId) === Number(data.facilityId)).purchaseLimit;
                const disableApproval = data.options?.some(op => op.moq > op.quantity);

                return (
                    <div className="card-action">
                        <div className="action-row">
                            {(data.options && data.options.length > 1) && !data.isVariantProduct ? (
                                <span className="more-options" onClick={() => this.setState({showBuyingOptions: true, buyingOptions: data.options, buyingOptionsImage: data.imagePath})}>
                                    Other Buying Options ({data.options.length - 1})
                                </span>
                            ) : null}
                            <Button secondary={true} className="alternate" onClick={() => this.setState({rejectItems: [data], showRejectDialog: true})}>
                                REJECT
                            </Button>
                            {approvalLimit >= data.extendedPrice ? (
                                <ApprovalPurchaseButton isDisabled={disableApproval} lineItemIds={[data.lineItemId]} />
                            ) : (
                                <Button
                                    secondary={true}
                                    disabled={disableApproval}
                                    onClick={() => !disableApproval ? this.onApprove(data) : ''}>
                                        APPROVE
                                </Button>
                            )}
                        </div>
                    </div>
                );
            }
            case CardActionType.AwaitingApprovalReadOnly:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.nextApprovalGroup ? (
                                <Button className="button-nudge" secondary={true} onClick={() => this.onNudgeApprover(data, false)}>
                                    Nudge Approver
                                </Button>
                            ) : null}
                        </div>
                    </div>
                );
            case CardActionType.Delivered:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <span className="more-options" onClick={() => history.push(data.detailUrl)}>
                                BUY IT AGAIN
                            </span>
                            {data.checkedIn === 'No' ? (
                                <Button secondary={true} className="alternate" onClick={() => this.setState({showCheckInDialog: true, selectedLineItem: data})}>
                                    Check In
                                </Button>
                            ) : null}
                            <Button secondary={true} onClick={() => this.setState({showReturnDialog: true, selectedLineItem: data})}>
                                RETURN
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.Shipped: 
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <span className="more-options" onClick={() => history.push(data.detailUrl)}>
                                BUY IT AGAIN
                            </span>
                            {data.checkedIn === 'No' ? (
                                <Button secondary={true} className="alternate" onClick={() => this.setState({showCheckInDialog: true, selectedLineItem: data})}>
                                    Check In
                                </Button>
                            ) : null}
                            <Button secondary={true} onClick={() => this.setState({showReturnDialog: true, selectedLineItem: data})}>
                                RETURN
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.OrderHistory:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button secondary={true} onClick={() => history.push(data.detailUrl)}>
                                BUY IT AGAIN
                            </Button>
                        </div>
                    </div>
                );
            case CardActionType.Exchange:
            case CardActionType.Return:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.rgaNumber ? (
                                <span className="more-options" onClick={() => this.onClickShowTrackingNumberDialog(data)}>
                                    ENTER TRACKING #
                                </span>
                            ) : null}
                            {data.rgaNumber ? (
                                <PDFButton
                                    buttonType="Secondary"
                                    title="Print Label & Documents"
                                    fileName={data.rgaNumber}
                                    pdfUrl={`ShoppingService/api/v1.0/lineItems/rgapaperwork/${data.lineItemId}/${data.rgaNumber}`}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            case CardActionType.Repair:
                if (data.groupName !== 'Initiated' || !data.rgaNumber || !(!data.canGenerateShippingLabel &&
                    (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) &&
                    (data.vendorTrackingNumber && data.isShipFromCustomer))) return null;
                if (data.groupName === 'Quoted') return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button secondary={true} onClick={() => history.push(`/quotes/${data.lineItemId}`)}>
                            REVIEW QUOTE
                            </Button>
                        </div>
                    </div>
                );
                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.rgaNumber ? (
                                <span className="more-options" onClick={() => this.onClickShowTrackingNumberDialog(data)}>
                                    ENTER TRACKING NUMBER
                                </span>
                            ) : null}
                            {data.canGenerateShippingLabel && <Button onClick={() => this.onPackingBundle(data.lineItemId)} secondary={true}>PRINT FORM</Button>}
                            {(!data.canGenerateShippingLabel &&
                                (!data.vendorRmaRequired || (data.vendorRmaRequired && data.vendorRmaNumber)) &&
                                (data.vendorTrackingNumber && data.isShipFromCustomer)) ?
                                <PDFButton
                                    title="Print Form"
                                    buttonType="Secondary"
                                    directLink={true}
                                    fileName={`PackingSlip${data.lineItemId}`}
                                    pdfUrl={`${process.env.REACT_APP_ORION_API}/ReportGenService/RgaReports/RgaPackingSlip?lineItemId=${data.lineItemId}`} /> : null}
                        </div>
                    </div>
                );
            case CardActionType.AltOptions:
                return (
                    <div className="card-action">
                        <div className="action-row">
                            <Button style={{marginRight: 0}} secondary={true} onClick={() => this.onSelectCard(data)}>SEE BUYING OPTIONS{data.altOptions && ` (${data.altOptions})`}</Button>
                        </div>
                    </div>
                )
            // These loaner Accept and Reject buttons will implemented later. See AP-10120 comments for dev notes
            //case CardActionType.LoanerQuoted: {
            //    {/* here */ }
            //    if (data.loanerAcceptanceTimestamp)
            //        return null;

            //    return (
            //        <div className="card-action">
            //            <div className="action-row">
            //                <Button secondary={true} className="alternate" onClick={() => this.rejectLoaner(data)}>
            //                    REJECT LOANER
            //                </Button>
            //                <Button secondary={true} onClick={() => this.acceptLoanerDialog(data)}>
            //                    ACKNOWLEDGE & ACCEPT LOANER UNIT
            //                </Button>
            //            </div>
            //        </div>
            //    );
            //}
            case CardActionType.LoanerReturn: {
                if (data.groupName !== 'OnLoan' && data.repairStatus !== 'Complete')
                    return null;

                return (
                    <div className="card-action">
                        <div className="action-row">
                            {data.customerTrackingNumber ? (
                                <div>
                                    <span className="more-options" onClick={() => this.onClickShowTrackingNumberDialog(data)}>
                                            ENTER TRACKING NUMBER
                                    </span>                            
                                    <PDFButton
                                        title="Print Form"
                                        buttonType="Secondary"
                                        directLink={true}
                                        fileName={`PackingSlip${data.lineItemId}`}
                                            pdfUrl={`${process.env.REACT_APP_ORION_API}/ReportGenService/RgaReports/RgaPackingSlip?lineItemId=${data.lineItemId}`} />

                                </div>
                            ): null}
                        </div>
                    </div>
                );
            }
            default:
                return null;
        }
    }

    onClickShowTrackingNumberDialog = (data) => {
        const {gridName} = this.props;

        let logObj = {
            'Reference Number': data.lineItemId,
            Origination: '',
        };

        if (gridName == 'repairsColumns') {
            logObj.Origination = 'Repair card view';
        } else {
            const {currentTab} = this.state;
            if (currentTab == 'returns') logObj.Origination = 'Return card view';
            else logObj.Origination = 'Exchange card view';
        }

        logEvent('Tracking Entry', logObj);

        this.setState({showTrackingNumberDialog: true, selectedLineItem: data});
    }

    getBadgeCount = (gridKey, props) => {
        const {searchText, columns} = this.state;
        const {data} = props || this.props;
        let keyValues = typeof gridKey.value === 'string' ? gridKey.value.split(',') : gridKey.value.toString();
        let items = _.filter(data, (v) => _.includes(keyValues, v[gridKey.fieldName].toString()));

        if (searchText && items.length > 0) {
            items = items.filter((x) => {
                let searchString = '';
                columns.filter((x) => x.visible).forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                columns
                    .filter((x) => x.visible && x.isNested)
                    .forEach((qc) => {
                        let nestedField = qc.field.split('.');
                        if (nestedField.length === 2) searchString += (x[nestedField[0]][nestedField[1]] || '').toString().toLowerCase();
                    });

                return searchString.indexOf(searchText) > -1;
            });
        }
        return items.length;
    }

    getVisibleGridItems() {
        const {data, gridKeys} = this.props;
        let results = [];

        if (gridKeys.length > 0) {
            data.forEach((i) => {
                gridKeys.forEach((k) => {
                    let keyValues = typeof k.value === 'string' ? k.value.split(',') : k.value.toString();
                    if (_.includes(keyValues, i[k.fieldName].toString()))
                        results.push(i);
                });
            });
        } else {
            results = data;
        }

        return results;
    }

    getGridItems() {
        const {currentTab, searchText, columns, view} = this.state;
        const {data, gridKeys} = this.props;

        let gridItems = [];

        if (gridKeys.length > 0) {
            const gridKey = gridKeys.filter((x) => x.id === currentTab)[0];
            if (!gridKey) return [];
            let keyValues = typeof gridKey.value === 'string' ? gridKey.value.split(',') : gridKey.value.toString();
            gridItems = _.filter(data, (v) => _.includes(keyValues, v[gridKey.fieldName].toString()));

            if (searchText && gridItems.length > 0) {
                gridItems = gridItems.filter((x) => {
                    let searchString = '';
                    columns.filter((x) => x.visible).forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                    columns
                        .filter((x) => x.visible && x.isNested)
                        .forEach((qc) => {
                            let nestedField = qc.field.split('.');
                            if (nestedField.length === 2) searchString += (x[nestedField[0]][nestedField[1]] || '').toString().toLowerCase();
                        });
                    return searchString.indexOf(searchText) > -1;
                });
            }
            gridItems = _.cloneDeep(gridItems).map((g) => {
                if (g.description) {
                    g.description = this.truncateDescriptionText(g.description);
                }
                if (view === ViewType.Grid && g.rgaCreatedTimestamp
                    && !g.canGenerateShippingLabel &&
                    (!g.vendorRmaRequired || (g.vendorRmaRequired && g.vendorRmaNumber)) &&
                    (g.trackingNumber && g.isShipFromCustomer)) {
                    g.hasRgaDocumentation = `${g.hasRgaDocumentation} | Label Created on ${moment(g.rgaCreatedTimestamp).format('MM/DD/YYYY')}`
                }
                return g
            })

            return gridItems;
        } else {
            gridItems = data;
            if (searchText && gridItems.length > 0) {
                gridItems = gridItems.filter((x) => {
                    let searchString = '';
                    columns.filter((x) => x.visible).forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                    return searchString.indexOf(searchText) > -1;
                });
            }

            gridItems = _.cloneDeep(gridItems).map((g) => {
                g.dateCreated = moment(g.dateCreated).format('MM/D/YYYY');
                g.description = this.truncateDescriptionText(g.description);
                if (view === ViewType.Grid && g.rgaCreatedTimestamp &&
                    !g.canGenerateShippingLabel &&
                    (!g.vendorRmaRequired || (g.vendorRmaRequired && g.vendorRmaNumber)) &&
                    (g.trackingNumber && g.isShipFromCustomer)) {
                    g.hasRgaDocumentation = `${g.hasRgaDocumentation} | Label Created on ${moment(g.rgaCreatedTimestamp).format('MM/DD/YYYY')}`
                }
                return g
            });

            return gridItems;
        }
    }

    renderLoanerCard = (data, cardActionType, hasActionButtons, height) => {
        return (
            <div className={hasActionButtons ? 'card-view' : 'card-view no-action-buttons'} style={{height: height + 'px'}}>
                <div>
                    {(data.groupName !== 'eQuoted' && data.groupName !== 'Requested') &&
                        <span className="order-label">{`ORDER #${data.orderId}`}</span>}
                    <span className="view-details" onClick={() => this.onSelectCard(data)}>
                        View Details
                    </span>
                    <span className="created-label">{`CREATED ${moment(data.created).format('MM/D/YYYY')}`}</span>
                </div>
                {data.urgencyId === 1 || data.priorityId === 1 ? (
                    <div className="hard-down">
                        <i className="fa fa-exclamation" />
                        <span>Critical Hard Down</span>
                    </div>
                ) : null}
                <div className="part-description" onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                    {data.isContractProOption && (
                        <div className="contract-badge">
                            <span>CONTRACT</span>
                        </div>
                    )}
                    {data.description}
                </div>
                <div>
                    <span className="part-image" onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                        {data.isServiceItem ? (
                            <img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 20}} />
                        ) : (
                            <ProductImage style={{width: '100px'}} url={data.imagePath} altText={data.imageAlt} />
                        )}
                    </span>
                    <span className="part-info">
                        <span className="detail">
                            Order Type: <span>{data.orderType}</span>
                        </span>
                        <span className="detail">
                            Equipment serial #: <span>{data.equipmentSerialNumber}</span>
                        </span>
                        <span className="detail">
                            Ref ID: <span>{data.lineItemId}</span>
                        </span>
                        <span className="detail">
                            Item #: <span>{data.partNumber}</span>
                        </span>
                        <span className="detail">
                            Facility: <span>{data.facility}</span>
                        </span>
                        <span className="detail">
                            Requester: <span>{data.requester}</span>
                        </span>
                        <span className="detail">
                            Repair Ref #: <span>{data.repairLineItemId}</span>
                        </span>
                    </span>
                </div>
                {this.renderShipSectionForLoaners(data, cardActionType)}
                {this.renderWarningSection(data, cardActionType)}
                {this.renderAltSourcingSection(data, cardActionType)}
                {this.renderAction(data, cardActionType)}                
            </div>
        );
    }

    renderCard = (data) => {
        const {currentTab} = this.state;
        const {
            gridKeys,
            cardAction,
            history,
            showShipInfo,
            user: {settings},
            height: cardHeight,
            onSelect,
            onSwitch,
            isVisualFormulary,
            gridName,
            subLabel,
        } = this.props;

        let cardActionType = gridKeys.length === 0 ? cardAction : gridKeys.filter((x) => x.id === currentTab)[0].cardAction;
        let selectedOption = data.options?.filter(x => x.isSelected)[0];
        
        let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];
        let gridHeight = gridKey && gridKey.height ? gridKey.height : null;
        let height = gridHeight || cardHeight || '680';

        let hasActionButtons = cardAction || (gridKey || {}).cardAction;

        if (hasActionButtons === 'Repair' && data.groupName !== 'Initiated') hasActionButtons = false;

        if (isVisualFormulary)
            return <FormularyCard data={data} onSwitch={onSwitch} onSelect={() => onSelect(data)} />;

        if (gridName == 'loanerColumns' && settings.enableLoanerFunctionality)
            return this.renderLoanerCard(data, cardActionType, hasActionButtons, height)

        return (
            <div className={hasActionButtons ? 'card-view' : 'card-view no-action-buttons'} style={{height: height + 'px'}}>
                <div>
                    {(data.groupName !== 'eQuoted' && data.groupName !== 'Requested') &&
                        <span className="order-label">{`ORDER #${data.orderId}`}</span>}
                    <span className="view-details" onClick={() => this.onSelectCard(data)}>
                        View Details
                    </span>
                    <span className="created-label">{`CREATED ${moment(data.created).format('MM/D/YYYY')}`}</span>
                </div>
                {data.urgencyId === 1 || data.priorityId === 1 ? (
                    <div className="hard-down">
                        <i className="fa fa-exclamation" />
                        <span>Critical Hard Down</span>
                    </div>
                ) : null}
                {cardActionType === CardActionType.AwaitingApproval &&
                    selectedOption && selectedOption.moq && selectedOption.moq > data.quantity ? (
                        <div className="note note--warning">
                            <p className="lbl-info">
                                Please select a min. quantity of {selectedOption.moq}
                            </p>
                        </div>
                    ) : null}
                <div className="part-description" onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                    {data.isContractProOption && (
                        <div className="contract-badge">
                            <span>CONTRACT</span>
                        </div>
                    )}
                    {data.description}
                </div>
                <div>
                    <span className="part-image" onClick={() => (data.detailUrl ? history.push(data.detailUrl) : null)}>
                        {data.isServiceItem ? (
                            <img src="/images/icn_repair.png" alt="Service Repair" style={{width: 60, marginLeft: 20}} />
                        ) : (
                            <ProductImage style={{width: '100px'}} url={data.imagePath} altText={data.imageAlt} />
                        )}
                    </span>
                    <span className="part-info">
                        { gridName === 'Loaner' ? 
                            <React.Fragment>
                                <span className="detail">
                                    Order Type: <span>{data.orderType}</span>
                                </span>
                                <span className="detail">
                                    Equipment serial #: <span>{data.serialNumber}</span>
                                </span>
                                <span className="detail">
                                    Ref ID: <span>{data.lineItemId}</span>
                                </span>
                                <span className="detail">
                                    Item #: <span>{data.partNumber}</span>
                                </span>
                                <span className="detail">
                                    Requester: <span>{data.requester}</span>
                                </span>
                                <span className="detail">
                                    Repair Ref #: <span>{data.repairLineItemId}</span>
                                </span>
                            </React.Fragment> : null
                        }

                        {!data.isContractProApproval && data.isFormularyApproval ? (
                            <span className="formulary-approval">
                                <i className="fa fa-exclamation" />
                                Non-Formulary Option Selected
                            </span>
                        ) : null}
                        {data.isContractProApproval ? (
                            <span className="formulary-approval">
                                <i className="fa fa-exclamation" />
                                Non-Contract Option Selected
                            </span>
                        ) : null}
                        {data.groupName !== 'Initiated' && data.price > 0 && <span className="price">{settings.hidePricing ? '' : formatMoney(data.price)}</span>}
                        {data.isServiceItem && data.manufacturer ? (
                            <span className="detail">
                                OEM: <span>{data.manufacturer}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.model ? (
                            <span className="detail">
                                Model Number: <span>{data.serviceInfo ? data.serviceInfo.model : ''}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.assetId ? (
                            <span className="detail">
                                Asset ID: <span>{data.assetId}</span>
                            </span>
                        ) : null}

                        <span className="detail">
                            Facility:<span>{data.facility}</span>
                        </span>

                        {data.isServiceItem && data.status ? (
                            <span className="detail">
                                Status: <span>{data.status}</span>
                            </span>
                        ) : null}

                        <span className="detail">
                            Ref #:<span>{data.lineItemId}</span>
                        </span>

                        {data.requester ? (
                            <span className="detail">
                                Requester:<span>{data.requester}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.serviceType && data.serviceInfo.serviceType !== ' ' ? (
                            <span className="detail">
                                Service Category: <span>{data.serviceInfo ? data.serviceInfo.serviceType : ''}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.workOrderNumber ? (
                            <span className="detail">
                                Work Order #: <span>{data.workOrderNumber}</span>
                            </span>
                        ) : null}

                        {data.isServiceItem && data.serviceInfo && data.serviceInfo.turnaroundTime ? (
                            <span className="detail">
                                Preferred Service Window: <span>{data.serviceInfo ? data.serviceInfo.turnaroundTime : ''}</span>
                            </span>
                        ) : null}
                        
                        {data.isServiceItem && data.created ? (
                            <span className="detail">
                                Created Date: <span>{data.created}</span>
                            </span>
                        ) : null}

                        {data.quantity ? (
                            <span className="detail">
                                Qty: <span>{data.quantity}</span>
                            </span>
                        ) : null}

                        {!(subLabel === 'Repairs' && data.groupName === 'Quoted') && !data.isServiceItem ? (
                            <span className="detail">
                                Condition:
                                <span>
                                    {data.condition} {data.purchaseChoice}
                                </span>
                            </span>
                        ) : null}
                        {data.lastApprovedBy && (cardActionType === CardActionType.AwaitingApproval || cardActionType === CardActionType.AwaitingApprovalReadOnly) ? (
                            <span className="detail">
                                Last Approved By:<span>{data.lastApprovedBy}</span>
                            </span>
                        ) : null}
                        {data.nextApprovalGroup && cardActionType === CardActionType.AwaitingApprovalReadOnly ? (
                            <span className="detail">
                                Awaiting Approval By:<span>{data.nextApprovalGroup}</span>
                            </span>
                        ) : null}
                        {cardActionType === CardActionType.AwaitingApproval ? (
                            <span className="detail">
                                Ship Method:<span>{data.carrierPriority}</span>
                            </span>
                        ) : null}
                        {cardActionType === CardActionType.Repair && data.serialNumber ? (
                            <span className="detail">
                                Serial Number: <span>{data.serialNumber}</span>
                            </span>
                        ) : null}
                        {showShipInfo ? (
                            <React.Fragment>
                                {/*
                                {data.estimatedShipDate ? <span className="detail">Estimated Ship Date:<span>{moment(data.estimatedShipDate).format('MMM DD, YYYY')}</span></span> : null}
                                {data.estimatedDelivery ? <span className="detail">ETA:<span>{moment(data.estimatedDelivery).format('MMM DD, YYYY')}</span></span> : null}
                                {data.trackingNumber ? <span className="detail">Tracking Number:<span>{data.trackingNumber}</span></span> : null}
                                */}
                            </React.Fragment>
                        ) : null}
                        {data.hasPODLetter && settings.proofOfDeliveryLinks && (<span className="detail pod-link" onClick={() => this.onShowPodLetter(data.lineItemId)}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />Proof of Delivery</span>)}
                    </span>
                </div>
                {this.renderShipSection(data, cardActionType)}
                {this.renderWarningSection(data, cardActionType)}
                {this.renderAltSourcingSection(data, cardActionType)}
                {this.renderAction(data, cardActionType)}                
            </div>
        );
    }

    renderLegend = (showFormularyLegend) => {
        const {gridKeys, data} = this.props;
        let rgaOrders = gridKeys.filter((x) => x.cardAction === CardActionType.Exchange || x.cardAction === CardActionType.Return);
        let loanerOrders = gridKeys.filter((x) => x.cardAction === CardActionType.LoanerQuoted);
        let priorityShipments = data.some((x) => x.isNextDayOvernight || x.isSecondDay);

        if (rgaOrders.length > 0) {
            return (
                <div className="legend-wrapper">
                    <span className="legendTitle">Legend:</span>
                    <div className="legend pastDue" />
                    <span>Past Due</span>
                    <div className="legend isDue" />
                    <span>Due within 5 days or less</span>
                </div>
            );
        } else if (loanerOrders.length > 0) {
            return (
                <div className="legend-wrapper">
                    <span className="legendTitle">Legend:</span>
                    <div className="legend pastDue" />
                    <span>Past Due</span>
                    <div className="legend isDue" />
                    <span>Due within 48 hours or less</span>
                </div>
            );
        } else if (priorityShipments) {
            return (
                <div className="legend-wrapper">
                    <span className="legendTitle">Legend:</span>
                    <div className="legend pastDue" />
                    <span>STAT and Overnight Orders</span>
                    <div className="legend isDue" />
                    <span>2nd Day Shipping</span>
                </div>
            );
        } else {
            return (
                <div className="legend-wrapper">
                    <span className="legendTitle">Legend:</span>
                    <div className="legend urgent" />
                    <span>Critical Items</span>
                    <div className="legend moq" />
                    <span>This item has a min. order qty.</span>
                    {showFormularyLegend && (
                        <React.Fragment>
                            <div className="legend formulary" />
                            <span>Non-Formulary Option Selected</span>
                        </React.Fragment>
                    )}
                </div>
            );
        }
    }

    onCheckBoxSelect = (items) => this.setState({checkboxItems: items, notification: ''});

    formatDate = (value) => {
        return value ? moment(value).format('MM/D/YYYY') : '';
    }
    onExport = () => {
        let {
            user: {
                settings: {hidePricing},
            },
            data,
        } = this.props;
        if (data.length === 0) return;

        const exportData = this.getVisibleGridItems();
        if (exportData.length === 0) return;

        let {columns} = this.state;

        if (hidePricing) {
            columns = columns.filter((x) => x.field !== 'price' && x.field !== 'extendedPrice' && x.field !== 'unitPrice' && x.field !== 'oemPrice');
        }

        let Json2csvParser = require('json2csv').Parser;
        Json2csvParser.flatten = true;
        let fields = [];
        columns.forEach((x) => fields.push({label: x.header, value: x.field}));
        const json2csvParser = new Json2csvParser({fields});

        exportData.forEach((item) => {
            columns.forEach((column) => {
                if ((column.type === 'date' || column.template === 'date') && item[column.field]) {
                    item[column.field] = this.formatDate(item[column.field]);
                }
            });
        });
        
        let csv = json2csvParser.parse(exportData);
        let csvFile = new Blob([csv], {type: 'text/csv'});

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(csvFile, 'DataExport.csv');
            return;
        }

        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvFile));
        link.setAttribute('download', 'DataExport.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
        this.sendLogExportExcel();
    }

    onConfirm = () => {
        const {onRefresh} = this.props;
        onRefresh();
    }

    onShowRejectItemsDialog = () => {
        const {checkboxItems} = this.state;
        this.setState({rejectItems: checkboxItems, showRejectDialog: true});
    }

    onChangeOption = () => {
        const {onRefresh} = this.props;
        this.setState({showBuyingOptions: false});
        onRefresh();
    }

    onRejectItems = () => {
        const {onRefresh} = this.props;
        this.setState({showRejectDialog: false, checkboxItems: []});
        onRefresh();
    }

    onPoSubmit = () => {
        const {onRefresh} = this.props;
        const {checkboxItems} = this.state;
        let notification = checkboxItems.length === 1 ? `(${checkboxItems.length}) item submitted PO#` : `(${checkboxItems.length}) items submitted PO#`;
        this.setState({notification, checkboxItems: []});
        onRefresh();
    }

    onPoDialogSubmit = () => {
        const {onRefresh} = this.props;
        this.setState({checkboxItems: [], showSubmitPoDialog: false});
        onRefresh();
    }

    renderColumnCheckbox = (id, label, checked) => <Checkbox id={id} key={id} label={label} checked={checked} tabIndex={0} className="column-checkbox" onChange={(e) => this.onColumnCheckboxChanged(e)} />;

    onColumnCheckboxChanged = (e) => {
        let {columnSelectionColumns} = this.state;
        columnSelectionColumns.filter((x) => x.field === e.id)[0].visible = e.checked;
        this.setState({columnSelectionColumns});
    }

    onViewChange = (view) => {
        const {disableCardView} = this.props;

        if (!disableCardView) {
            this.setState({view: view, checkboxItems: [], notification: ''});
            this.saveGridPreferences(null, view);
            this.sendLog(view);
        }
    }

    handleRequesterToggle = (e, result) => {
        const {currentTab, requesterViewType} = this.state;
        const {
            onRequesterToggle,
            location: {pathname},
            gridName,
        } = this.props;
        const newViewType = result.value.selection == 'Left' ? RequesterViewType.CurrentUser : RequesterViewType.AllRequesters;

        this.setState({requesterViewType: newViewType});
        if (getPersonalizationCookiesEnabled())
            setCookie(`requesterViewType-${gridName}`, newViewType)

        logEvent('Mine vs Everyone', {
            'Switch toggle': requesterViewType,
            'Toggled page': pathname,
            'Toggled tab': currentTab,
        });

        if (onRequesterToggle) onRequesterToggle(newViewType);
    }

    onSelectCard = (card) => {
        const {onSelect} = this.props;
        this.sendLogViewDetail(card);
        onSelect(card);
    }

    sendLog = (viewType) => {
        const {currentTab, tabCounts, id_ins} = this.state;
        const {
            gridKeys,
            location: {pathname},
        } = this.props;

        if (!currentTab) return;
        const currentTabName = gridKeys.find((x) => x.id === currentTab).tabDisplay;
        const tabCount = tabCounts.find((x) => x.id === currentTab).count;

        logEvent('GRID CARD VIEW CHANGE', {
            Page: pathname.replace('/', ''),
            Tab: currentTabName,
            'View Setting': viewType,
            'Num Records on active tab': tabCount,
        });
    }

    sendLogTabClick = (ind, defaultTab) => {
        const {tabCounts, id_ins} = this.state;
        const {
            gridKeys,
            location: {pathname},
        } = this.props;

        if (ind === null || gridKeys.length < 1) return;
        const currentTabName = (gridKeys.find((x) => x.id === ind) || {}).tabDisplay;
        if (!currentTabName) {
            window.location.href = window.location.pathname;
            return;
        }
        const tabCount = tabCounts.length > 0 ? (!ind ? tabCounts[0].count : tabCounts.find((x) => x.id === ind).count) : 0;

        logEvent('TAB ACCESS', {
            Page: pathname.indexOf('/orders/rga') > -1 ? 'Exchanges & Returns' : pathname.replace('/', ''),
            Tab: currentTabName,
            Count: tabCount,
        });

        if (defaultTab) this.setState({defaultTab: false});
    }

    sendLogViewDetail = (row) => {
        const {
            location: {pathname},
        } = this.props;
        const {id_ins} = this.state;
        let pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('VIEW DETAILS', {
            id_ins: id_ins,
            'Order number': row.orderId,
            'Ref number': row.lineItemId,
            'Detail type': pageName,
            'Detail state': row.groupName || row.status || row.statusName,
        });
    }

    sendLogShowHideColumns = (newColumns, c) => {
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, columns, id_ins} = this.state;
        const newCols = newColumns.filter((x) => x.visible);
        const cols = columns.filter((x) => x.visible);
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        let logDataDetails = [];
        logDataDetails.push({name: 'id_ins', value: id_ins});
        logDataDetails.push({name: 'Page', value: pageName});
        logDataDetails.push({name: 'Tab', value: currentTab || pageName});
        cols.forEach((oc) => {
            if (!newCols.some((nc) => nc.field === oc.field)) logDataDetails.push({name: 'Column Deleted', value: `${oc.header}`});
        });
        newCols.forEach((nc) => {
            if (!cols.some((oc) => nc.field === oc.field)) logDataDetails.push({name: 'Column Added', value: `${nc.header}`});
        });

        if (logDataDetails.length > 2) logEventVerbose('SHOW HIDE COLUMNS', logDataDetails);
    }

    sendLogExportExcel = () => {
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, id_ins} = this.state;
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('EXPORT EXCEL', {
            Page: pageName,
            Tab: currentTab || pageName,
        });
    }

    sendLogViewHistory = (data) => {
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, id_ins} = this.state;
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('VIEW HISTORY', {
            'View History': data.lineItemId,
            Age: data.closedDate ? moment(new Date().valueOf()).diff(moment(Date.parse(data.closedDate)).valueOf(), 'minutes') : 0,
            'Ship Priority': data.carrierPriority,
            Page: pageName,
            Tab: currentTab || pageName,
        });

        this.setState({showTrackingHistory: true, selectedLineItem: data});
    }

    sendLogTrackShipping = (e, data) => {
        if (!data || (data && !data.lineItemId)) return;

        e.preventDefault();
        const {
            location: {pathname},
        } = this.props;
        const {currentTab, id_ins} = this.state;
        const pageName = pathname.indexOf('/orders/') > -1 ? 'Orders' : pathname.replace('/', '');

        logEvent('Track Shipping', {
            'Track Shipping': data.lineItemId,
            'Ship Priority': data.carrierPriority ? data.carrierPriority : '',
            Age: data.closedDate ? moment(new Date().valueOf()).diff(moment(Date.parse(data.closedDate)).valueOf(), 'minutes') : 0,
            Page: pageName,
            Tab: currentTab,
        });
        window.open(e.target.href);
    }

    saveGridPreferences = (newColumns, newView) => {
        const {gridName} = this.props;
        const {columns, view} = this.state;
        let request = {
            gridName: gridName,
            view: newView || view,
            columns: newColumns || columns,
        };
        axios.post('/ShoppingService/api/v1/account/save/gridSetting', request);
        if (newColumns && newColumns !== columns) this.sendLogShowHideColumns(newColumns, columns);
        this.setState({showColumnSelection: false, columnSelectionColumns: []});
    }

    handleOnCheckInConfirm = () => {
        const {selectedLineItem, requesterViewType} = this.state;
        this.setState({showCheckInPartPopup: false});
        const {updatePartReceived, onRefresh} = this.props;
        updatePartReceived(selectedLineItem).then(() => {
            this.setState({showCheckInDialog: false});
            if (onRefresh) onRefresh(requesterViewType);
        });
    }

    handleOnReturnConfirm = () => this.setState({showReturnDialog: false, selectedLineItem: {}});

    rowClassName = (rowData) => {
        let selectedOption = rowData.options?.filter(x => x.isSelected)[0];

        return {
            gridhighlightedRow: rowData.priority === 'Stat' || rowData.priority === 'Expedited' || rowData.priority === 'Critical',
            pastDueRow: rowData.isOverDue || rowData.isNextDayOvernight,
            isDueRow: rowData.isDue || rowData.isSecondDay,
            isFormularyRow: rowData.isFormularyApproval,
            moqRow: selectedOption && selectedOption.moq && selectedOption.moq > rowData.quantity,
        };
    }

    onSelectItem = (checkboxItems) => {
        if (checkboxItems == null) return;
        if (checkboxItems.length === 0 || checkboxItems.length > 1) {
            this.setState({checkboxItems, notification: ''});
        }
    }

    onRowSelect = (e) => {
        const {onSelect, selectionMode, isVisualFormulary} = this.props;
        
        let isCustomCheckboxEvent = !isVisualFormulary &&
            e.originalEvent.target.parentElement.getElementsByTagName('input').length > 0 && e.originalEvent.target.parentElement.getElementsByTagName('input')[0].type === 'checkbox';

        let selection = window.getSelection();
        if (selection.type === 'Range') {
            return;
        }

        if (isCustomCheckboxEvent && e.originalEvent.target.parentElement.getElementsByTagName('input')[0].hasAttribute('disabled')) {
            e.originalEvent.stopPropagation();
            return false;
        }

        if (e.originalEvent.target.parentElement && 
            e.originalEvent.target.parentElement.parentElement &&
            (e.originalEvent.target.classList.contains('stop-propogation') ||
            e.originalEvent.target.parentElement.classList.contains('stop-propogation') ||
            e.originalEvent.target.parentElement.parentElement.classList.contains('stop-propogation'))) {
            e.originalEvent.stopPropagation();
            return false;
        }

        e.originalEvent.stopPropagation();

        let shouldCheckForUrls = this.shouldCheckForUrlsData(e);

        if (e.type === 'checkbox' || isCustomCheckboxEvent) {
            let {checkboxItems, shipViaId} = this.state;
            let exists = checkboxItems.filter((x) => Number(x.lineItemId) === Number(e.data.lineItemId));
            if (exists.length > 0) {
                _.remove(checkboxItems, (x) => Number(x.lineItemId) === Number(e.data.lineItemId));
            } else {
                checkboxItems.push(e.data);
            }

            this.setState({checkboxItems, notification: ''});
        } else if (shouldCheckForUrls) {
            const urlsData = getTrackingNumbersUrlsData(e.data.trackingNumbers, e.data.lineItemId);

            _.forEach(urlsData, function (urlData) {
                window.open(urlData.url);
            });
        } else if (e.originalEvent.target.attributes['data-invoiceNumber'] && e.originalEvent.target.attributes['data-lineItemId']) {
            let invoiceNumber = e.originalEvent.target.attributes['data-invoiceNumber'].value;
            let lineItemId = e.originalEvent.target.attributes['data-lineItemId'].value;
            this.showInvoice(lineItemId, invoiceNumber);
        } else {
            if (onSelect) {
                this.sendLogViewDetail(e.data);
                onSelect(e.data);
            }
        }
    }

    closest = (el, selector) => {
        let matchesFn;

        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] === 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    };

    shouldCheckForUrlsData = (e) => {
        let shouldCheck = false;
        let clickedEl = this.closest(e.originalEvent.target, '.preformatted');

        if (!clickedEl) return false;

        for (let i = 0; i < clickedEl.childNodes.length; i++) {
            if (clickedEl.childNodes[i].className == 'ui-cell-data') {
                let clickedElData = clickedEl.childNodes[i].firstChild;

                if (clickedElData.attributes && clickedElData.attributes['data-trackingnumber'] && clickedElData.attributes['data-shipviaid']) {
                    shouldCheck = true;
                }
            }
        }

        return shouldCheck;
    }

    showInvoice = (lineItemId, invoiceNumber) => {
        const pdfUrl = `/ShoppingService/api/v1/lineitems/invoice/${lineItemId}/${invoiceNumber}`;
        axios.get(pdfUrl, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', Accept: 'application/pdf'}}).then((x) => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (file.size === 0) {
                this.setState({showMissingFileModal: true, missingFileMsg: 'This invoice is not currently available.'});
                return;
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = `invoice.pdf`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);
        });
    }

    onColReorder = (e) => {
        const {selectionMode} = this.props;
        const {columns: stateColumns} = this.state;

        let columns = _.orderBy(stateColumns, ['visible', 'order'], ['desc', 'asc']);

        if (selectionMode === 'multiple') arrayMoveMultiGrid(columns, e.dragIndex - 1, e.dropIndex - 1);
        else arrayMoveMultiGrid(columns, e.dragIndex, e.dropIndex);

        for (let i = 0; i < columns.length; i++) {
            columns[i].order = i;
        }

        this.setState({columns});
        this.saveGridPreferences(columns);
    }

    onTrackingNumberSubmitted = () => {
        const {onRefresh} = this.props;
        onRefresh();
        this.setState({showTrackingNumberDialog: false});
    }

    onSort = (e, type) => {
        let data = this.getGridItems();

        switch (type) {
            case 'date':
                data.sort(function compare(a, b) {
                    let dateA = new Date(a[e.field]);
                    let dateB = new Date(b[e.field]);
                    return e.order === 1 ? dateA - dateB : dateB - dateA;
                });
                break;
            case 'number': {
                data.sort(function compare(a, b) {
                    let numA = parseFloat(a[e.field]);
                    let numB = parseFloat(b[e.field]);

                    if (Number.isNaN(numA)) numA = 0;
                    if (Number.isNaN(numB)) numB = 0;

                    return e.order === 1 ? numA - numB : numB - numA;
                });
                break;
            }
        }

        return data;
    }

    onShowColumnSelection = () => {
        const {columns} = this.state;
        let columnSelectionColumns = _.cloneDeep(columns);
        this.setState({columnSelectionColumns, showColumnSelection: true});
    }

    onSaveColumnSelection = () => {
        const {columnSelectionColumns} = this.state;
        this.saveGridPreferences(columnSelectionColumns, null);
        this.setState({columns: columnSelectionColumns});
        this.resetTableWidth();
    }

    onColumnResizeEnd = (e, i) => {
        if ($('#custom-grid').width() < $('.grid-container').width()) {
            this.resetTableWidth();
        } else {
            $('.ui-paginator-bottom').css('width', $('.grid-container').width());
        }
    }

    resetTableWidth = () => {
        $('#custom-grid').css('width', '');
        $('#custom-grid table').css('width', '');
        $('.ui-paginator-bottom').css('width', '');
    }

    columnStyle = (column) => {
        if (column.type === 'date') return {width: '100px'};

        switch (column.header) {
            case 'Description':
            case 'Facility':
                return {minWidth: '250px'};
            case 'PO #':
                return {minWidth: '110px'};
            case 'Requester':
            case 'Last Approved By':
            case 'Manufacturer':
            case 'Carrier / Priority':
                return {minWidth: '150px'};
            default:
                return null;
        }
    }

    onTrackingNumberNumberClick(e) {
        e.originalEvent.stopPropagation();
        return false;
    }
    
    columnTemplate(rowData, column) {
        const {columns, tooltipLineItemId, tooltipLocation} = this.state;
        const {user: {settings: {isShowBackorderToolTips, proofOfDeliveryLinks}}, onSwitch} = this.props;
        let c = columns.find((x) => x.field === column.field);

        switch (c.template) {
            case 'checkMark':
                return (
                    <div className="checkMark-column" onClick={(e) => this.onTrackingNumberNumberClick(e)}>
                        {rowData[c.field] ? <i className="fa fa-check" /> : null}
                    </div>
                );
            case 'trackingNumber':
                return rowData.trackingNumber ? (
                    <div data-trackingNumber={rowData.trackingNumber} data-shipViaId={rowData.shipViaId} className="link">
                        {rowData.trackingNumber}
                    </div>
                ) : null;
            case 'invoiceNumber':
                return rowData.invoiceNumber ? (
                    <div data-invoiceNumber={rowData.invoiceNumber} data-lineItemId={rowData.lineItemId} className="link">
                        {rowData.invoiceNumber}
                    </div>
                ) : null;
            case 'estimatedDelivery':
                if ((rowData.groupName === 'Delivered' || rowData.status === 'Delivered'))
                    return ''
                else
                    return rowData.estimatedDelivery
                        ? moment(rowData.estimatedDelivery).hours() === 0
                            ? moment(rowData.estimatedDelivery).format('MM/DD/YY') + ' by EOD'
                            : moment(rowData.estimatedDelivery).format('MM/DD/YY h:mm A')
                        : 'Pending';
            case 'date':
                if (column.field === 'estimatedShipDate') {
                    const dateText = rowData.estimatedShipDate;
                    const isBackOrdered = rowData.backordered === 'Yes';

                    let textDecoration = 'none';
                    let displayDate = '';
                    let tooltip = '';
                    let showTooltip = isShowBackorderToolTips;

                    const tooltipStyle = tooltipLocation.useTop ? {top: tooltipLocation.top, left: tooltipLocation.left} :
                        {bottom: tooltipLocation.bottom, left: tooltipLocation.left};

                    if (rowData.status === 'Shipped' || rowData.groupName === 'Shipped') {
                        displayDate = 'Shipped';
                        showTooltip = false;
                    } else if (rowData.status === 'Delivered' || rowData.groupName === 'Delivered') {
                        displayDate = 'Delivered';
                        showTooltip = false;
                    } else if (dateText) {
                        displayDate = moment(rowData[c.field]).format('MM/DD/YY');

                        if (moment().isAfter(moment(dateText))) {        
                            textDecoration = 'line-through';
                            tooltip = 'The original ship date provided by the supplier has passed. We are actively following up to obtain a ship date from the supplier, updates will be provided as soon as available.';              
                        } else {
                            tooltip = 'An estimated ship date has been provided by the supplier. We will continue to follow up for updates.  Alternative sources are being researched ongoing, to help avoid delays.';
                        }
                    } else {
                        if (isBackOrdered) {
                            displayDate = 'B/O, Date Unavailable';
                            tooltip = 'Your order has been confirmed; the supplier is unable to provide a ship date at this time.  We are actively following up and an update will be provided as soon as available.';
                        } else {
                            displayDate = 'N/A';
                            tooltip = 'We are actively working with the supplier on an update. A ship date will be provided as soon as available.';
                        }
                    }
                  
                    return (<span className="help-icon-link">
                        <span style={{textDecoration: textDecoration}}>{displayDate}</span> { showTooltip && <i className="fa fa-question-circle" onClick={(e) => this.onShowTooltip(e, 'tooltip here', rowData.lineItemId)} /> } 
                        {tooltipLineItemId === rowData.lineItemId && showTooltip && 
                        <React.Fragment>
                            <span className="tooltip-background" onClick={(e) => this.onHideTooltip(e)} />
                            <span className="tooltip-content" style={tooltipStyle}>
                                {tooltip}
                            </span>
                        </React.Fragment>}
                    </span>)
                } else {
                    return rowData[c.field] ? moment(rowData[c.field]).format('MM/DD/YY') : '';
                }              
            case 'money':
                if (c.decimals === 0) return (rowData[c.field] || rowData[c.field] === 0) ? `$${rowData[c.field].toLocaleString()}` : '';
                return (rowData[c.field] || rowData[c.field] === 0) ? formatMoney(rowData[c.field]) : '';
            case 'switch':
                return (
                    <div style={{width: '50px' ,textAlign: 'center', margin: 'auto'}}>
                        <SwitchCheckbox
                            label=""
                            className="auto-refresh-toggle stop-propogation"
                            value={rowData[c.field]}
                            onChange={(checked) => onSwitch(rowData, checked)} />
                    </div>         
                )
            case 'detailUrl':
                return rowData.detailUrl && rowData.displayPartNumber ? (
                    <NavLink className="grid-header-link" style={{marginLeft: '15px'}} to={rowData.detailUrl} target="_blank">
                        {rowData.displayPartNumber}
                    </NavLink>
                ) : <div>{rowData.displayPartNumber || 'N/A'}</div>;
            case 'visualFormularyFacilites':
                return (
                    <div className="facility-cell" dangerouslySetInnerHTML={{__html: rowData[c.field]}} />
                )
            case 'initiateLink':
                return <a href="#" onClick={() => this.setState({showReturnDialog: true, selectedLineItem: rowData})} className="link">
                        Return
                    </a>;
            case 'visualFormularyDisplayPreference':
                return (
                    <div className="display-type-cell">
                        <div>Type:<span className="pref-value">{rowData.displayType}</span></div>
                        <div>Route for Approval:<span className="pref-value">{rowData.requireApproval}</span></div>
                        <div>Display "Preferred Choice":<span className="pref-value">{rowData.displayPreferredChoice}</span></div>
                    </div>
                )
            case 'visualFormularyScore':
                const icnStyle = {
                    width: '15px',
                    position: 'relative',
                    top: '-1px',
                };

                const descStyle = {
                    paddingLeft: '6px',
                    paddingRight: '2px',
                    fontSize: '14px',
                };

                switch (rowData.formularyScoreRanking) {
                    case 1:
                        return <div><img style={icnStyle} src="/images/formulary/icn_best.png" /><span style={descStyle}>BEST:</span><span style={{fontWeight: '600'}}>{rowData.formularyScore.toFixed(2)}</span></div>
                    case 2:
                        return <div><img style={icnStyle} src="/images/formulary/icn_very-good.png" /><span style={descStyle}>VERY GOOD:</span><span style={{fontWeight: '600'}}>{rowData.formularyScore.toFixed(2)}</span></div>
                    case 3:
                        return <div><img style={icnStyle} src="/images/formulary/icn_moderate.png" /><span style={descStyle}>MODERATE:</span><span style={{fontWeight: '600'}}>{rowData.formularyScore.toFixed(2)}</span></div>
                    case 4:
                        return <div><img style={icnStyle} src="/images/formulary/icn_fair.png" /><span style={descStyle}>FAIR:</span><span style={{fontWeight: '600'}}>{rowData.formularyScore.toFixed(2)}</span></div>
                    case 5:
                        return <div><img style={icnStyle} src="/images/formulary/Poor.png" /><span style={descStyle}>POOR:</span><span style={{fontWeight: '600'}}>{rowData.formularyScore.toFixed(2)}</span></div>                          
                    default:
                        return '';
                }
            case 'podLetter':
                return rowData.hasPODLetter && proofOfDeliveryLinks ? <div className="stop-propogation link" onClick={() => this.onShowPodLetter(rowData.lineItemId)}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />Proof of Delivery</div> : null
            case 'fsrDoc':
                return this.renderFsrDocumentsColumn(rowData); 
            case 'creditMemoDoc':
                if (rowData.creditMemoNumber) {
                    return <a href='#' className="link" onClick={() => downloadCreditMemos(rowData.lineItemId)}>Print credit memo(s)</a>
                } else {
                    return '';
                }
            default:
                return null;
        }
    }

    renderFsrDocumentsColumn = (rowData) => {        
        if (!(rowData && rowData.fsrDocuments && rowData.fsrDocuments.length > 0)) return null;

        let {showAllFsrDocuments} = this.state;        
        const qtyToDisplay = showAllFsrDocuments[rowData.lineItemId] ? rowData.fsrDocuments.length : 3;
        
        return (
            <div className="stop-propogation">
                {rowData.fsrDocuments.slice(0,qtyToDisplay).map((d,i) => (<div data-ea-download-link="FSR Document" key={`fsr-doc-${d.lineItemId}-${i}`} className="link" style={{marginBottom: '5px'}} onClick={() => this.onShowFSRDocument(d)}><i className="fa fa-file-text-o" style={{paddingRight: '5px'}} />{d.fileName}</div>))}
                {!showAllFsrDocuments[rowData.lineItemId] && rowData.fsrDocuments.length > 3 ? 
                    <div style={{marginTop: '15px'}} onClick={() => {
                        showAllFsrDocuments[rowData.lineItemId] = true;
                        this.setState({showAllFsrDocuments});
                    }}>Show more ({rowData.fsrDocuments.length - 3})</div> : null}
            </div>
        )
    }

    renderCheckboxColumn = (rowData) => {
        const {checkboxItems} = this.state;
        const isChecked = checkboxItems.find((x) => x.lineItemId == rowData.lineItemId) != undefined;
        const isDisabled = (checkboxItems || []).length > 0 && checkboxItems[0].orderType != rowData.orderType;
        const title = isDisabled ? "Service and Non-Service items can't be purchased together" : '';
        return (
            <span title={title}>
                <Checkbox className="custom-checkbox-container" checked={isChecked} disabled={isDisabled} />
            </span>
        );
    }

    renderMissingFileModal = () => {
        const {missingFileMsg} = this.state;
        return (
            <Popup show={true} hideButtons={true} onCancel={() => this.setState({showMissingFileModal: false})}>
                <div>{missingFileMsg}</div>
            </Popup>
        );
    }

    onPageSelect = (pageNumber) => this.setState({currentPage: parseInt(pageNumber)});

    onPageSizeChange = (selectedPageSize) => {
        this.setState({selectedPageSize, currentPage: 1})
        if (this.props.emitPageSizeChanged) {
            this.props.emitPageSizeChanged(selectedPageSize)
        }
    };

    checkToggle = () => {
        const {
            subLabel,
            user: {
                settings: {showTechNames, showOtherTechQuotes, showAllRepairs, showAllRecords, showOthersServiceEvents},
            },
            showRequesterToggle,
        } = this.props;
        const order = subLabel.indexOf('Orders') > -1;
        const quotes = subLabel === 'Parts Quotes';
        const exchngReturn = subLabel === 'Exchanges & Returns';
        const repairs = subLabel === 'Depot Repairs & Quotes';
        const ods = subLabel.indexOf('On-Site') > -1;
        if (showRequesterToggle && ((order && showTechNames) || (ods && showOthersServiceEvents) || (quotes && showOtherTechQuotes) || (exchngReturn && showAllRecords) || (repairs && showAllRepairs)))
            return true;
    }

    onShowTooltip = (e, msg, lineItemId) => {   
        let container = e.currentTarget.closest('.grid-container');
        let table = e.currentTarget.closest('table');
        let cell = e.currentTarget.closest('td'); 
        const loc = cell.getBoundingClientRect();
        const tbl = table.getBoundingClientRect();

        let top = loc.top - tbl.top + 30;
        let left = loc.left - tbl.left + 30;
        let bottom = 0;

        if (top + 140 > tbl.height) {
            bottom = tbl.bottom - loc.bottom + 50;
        }

        if (tbl.height < 120 && bottom < 100) {
            bottom = -20;
        }

        if (left + 250 > tbl.width) {
            left = loc.left - loc.width;

            if (container.scrollLeft > 0) {
                left = left + container.scrollLeft - 30;
            }
        }

        let tooltipLocation = {
            top: `${top}px`,
            left: `${left}px`,
            bottom: `${bottom}px`,
            useTop: bottom === 0 || (tbl.bottom - loc.bottom + 50) > 100,
        }  

        this.setState({tooltipLineItemId: lineItemId, tooltipLocation: tooltipLocation});  
        e.stopPropagation();
    }

    onHideTooltip = (e) => {
        this.setState({tooltipLineItemId: -1});
        e.stopPropagation();
    }

    onShowPodLetter = (lineItemId) => {
        getPODLetter(lineItemId, this.props.network.tokenInfo, 'MultiGrid').then(x => {
            if (!x) this.setState({showMissingFileModal: true, missingFileMsg: 'This document is not currently available.'});
        });  
    }

    onShowFSRDocument = (doc) => {
        getFSRDocument(doc).then(x => {
            if (!x) this.setState({showMissingFileModal: true, missingFileMsg: 'This document is not currently available.'});
        });
    }

    showConfirmReturnModal = ( value ) => {
        this.setState({ showConfirmation: value })
    }

    renderReturnConfirmMessage = () => {
        return (<Popup
            confirmText="OK"
            show={this.state.showConfirmation}
            onConfirm= {() => this.setState({ showConfirmation: false })}
            onCancel = {() => this.setState({ showConfirmation: false })}
            onClose = {() => this.setState({ showConfirmation: false })}
            className="asset-information-dialog">
            <div className="asset-information-dialog">
                <span>{RETURN_CONFIRMATION_MSG}</span>            
            </div>
        </Popup>)
    }

    render() {
        const {
            currentTab,
            checkboxItems,
            showBuyingOptions,
            buyingOptions,
            buyingOptionsImage,
            showRejectDialog,
            showSubmitPoDialog,
            rejectItems,
            tabCounts,
            showColumnSelection,
            columnSelectionColumns,
            showCheckInDialog,
            showReturnDialog,
            selectedLineItem,
            showTrackingNumberDialog,
            showNudgeConfirmDialog,
            showTrackingHistory,
            showMissingFileModal,
            defaultTab,
            renderGridMobile,
            showFormularyFacetsModal,
            showLoanerAcceptanceDialog,
        } = this.state;

        const {
            disableCardView,
            gridKeys,
            cardAction,
            children,
            loadingData,
            subLabel,
            location: {pathname},
            isVisualFormulary,
            onRefresh,
            pendingChanges,
            onClearFilters,
            user: {settings},
        } = this.props;

        let cardViewClass = 'icon-view-selector';
        cardViewClass += disableCardView ? ' disable' : ' selected';

        let purchaseLines = [];
        if (cardAction === CardActionType.AwaitingPO && checkboxItems.length > 0) {
            purchaseLines = checkboxItems.map((x) => x.purchaseInfo);
        }

        if (!loadingData && currentTab && defaultTab) this.sendLogTabClick(currentTab, true);

        const currentTabTitle = (currentTab && (gridKeys.find(k => k.id === currentTab) || {}).tabDisplay) || '';

        return (
            <div className={`${pathname === '/orders/history' ? 'multi-grid-page' : 'multi-grid-page multi-grid-page--sticky'}`}>
                {this.renderHeader()}
                <div>{children}</div>

                {renderGridMobile && <PhonePortrait>
                    <MobileView className="multi-grid-page" style={{marginTop: '0px', zIndex: 1049}} header={this.renderToolbar()} title={currentTabTitle} onClose={() => this.setState({renderGridMobile: false})}>
                        {this.renderGrid()}
                    </MobileView>
                </PhonePortrait>}
                {isVisualFormulary && 
                    <React.Fragment>
                        <ProFormaSavingsBanner metrics={this.props.metrics} onChange={(d, e) => onRefresh(d, e)} />
                        {settings.editFormularyRules ? <FormularyPendingChanges count={pendingChanges} onReset={() => onRefresh({isReset: true})} onSave={this.props.onSave} /> : null}
                    </React.Fragment>                    
                }
                {showFormularyFacetsModal && <FormularyFacetsModal onClose={() => this.setState({showFormularyFacetsModal: false})} facets={this.props.facets || []} onChange={this.props.onFormularyFacetChange} count={(this.props.data || []).length} onClearFilters={onClearFilters} />}
                <div className="grid-bottom">
                    {this.renderTabStrip()}
                    <Default>{this.renderGrid()}</Default>
                    {(pathname === '/orders/history') || (!renderGridMobile && !tabCounts.length) ? <PhonePortrait>{this.renderGrid()}</PhonePortrait> : null}
                </div>

                {showBuyingOptions ? (
                    <BuyingOptionsDialog options={buyingOptions} imagePath={buyingOptionsImage} onSave={() => this.onChangeOption()} onCancel={() => this.setState({showBuyingOptions: false})} />
                ) : null}

                {showRejectDialog ? <RejectApprovalDialog items={rejectItems} onReject={() => this.onRejectItems()} onCancel={() => this.setState({showRejectDialog: false})} /> : null}

                {showSubmitPoDialog ? (
                    <SubmitPoForm
                        purchaseLines={purchaseLines}
                        onSubmit={() => this.onPoDialogSubmit()}
                        showPopup={true}
                        onCancel={() =>
                            this.setState({
                                showSubmitPoDialog: false,
                                checkboxItems: [],
                            })
                        }
                    />
                ) : null}
                {showColumnSelection ? (
                    <Popup
                        confirmText="Save Columns"
                        cancelText="Cancel"
                        onCancel={() => this.setState({showColumnSelection: false})}
                        show={true}
                        onConfirm={() => this.onSaveColumnSelection()}
                        className="company-selection-popup"
                    >
                        <div className="selection-title">Show / Hide Columns</div>
                        {columnSelectionColumns.map((c, i) => this.renderColumnCheckbox(c.field, c.header, c.visible))}
                    </Popup>
                ) : null}

                {showCheckInDialog ? (
                    <Popup
                        confirmText="OK"
                        cancelText="Cancel"
                        show={true}
                        onConfirm={() => this.handleOnCheckInConfirm()}
                        onCancel={() =>
                            this.setState({
                                showCheckInDialog: false,
                                selectedLineItem: {},
                            })
                        }
                        className="asset-information-dialog"
                    >
                        <div className="asset-information-dialog">
                            <span>
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" /> Please confirm you are acknowledging receipt of the part(s).
                            </span>
                        </div>
                    </Popup>
                ) : null}
                {showReturnDialog ? (
                    <div className="return-order-details">
                        <InitiateReturnModal 
                            item={selectedLineItem}
                            showConfirmReturnModal={(val) => this.showConfirmReturnModal(val)}
                            onCancel={() => this.setState({showReturnDialog: false})} />
                    </div>
                ) : null}

                {showTrackingNumberDialog ? (
                    <TrackingNumberEntryDialog rga={selectedLineItem} onCancel={() => this.setState({showTrackingNumberDialog: false})} onSave={() => this.onTrackingNumberSubmitted()} />
                ) : null}
                {showNudgeConfirmDialog ? (
                    <Popup
                        show={true}
                        hideButtons={true}
                        onCancel={() =>
                            this.setState({
                                showNudgeConfirmDialog: false,
                                selectedLineItem: {},
                            })
                        }
                        className="asset-information-dialog"
                    >
                        <div className="asset-information-dialog">
                            <h2>Nudge Approver for Order #{selectedLineItem.lineItemId}</h2>
                            <span className="blueText">Email reminder sent successfully!</span>
                            <br />
                            <br />
                            <span>
                                Your approval &quot;nudge&quot; for Order #{selectedLineItem.lineItemId} was sent to {selectedLineItem.nextApprovalGroup}
                            </span>
                        </div>
                    </Popup>
                ) : null}

                {showTrackingHistory ? (
                    <TrackingPanel show={true} status={selectedLineItem.status} lineItemId={selectedLineItem.lineItemId} hasPODLetter={selectedLineItem.hasPODLetter ? true : false} onClose={() => this.setState({showTrackingHistory: null})} />
                ) : null}

                {showMissingFileModal ? this.renderMissingFileModal() : null}

                {showLoanerAcceptanceDialog ? (
                    <LoanerAcceptanceDialog lineItemId={selectedLineItem.lineItemId} onConfirm={() => this.loanerAccepted()} onCancel={() => this.setState({showLoanerAcceptanceDialog: false})} />
                ) : null}
                {this.renderReturnConfirmMessage()}
            </div>
        );
    }

    renderGrid = () => {
        const {
            view,
            checkboxItems,
            notification,
            currentPage,
            selectedPageSize,
            tabCounts,
            currentTab,
            columns: columnsCandidate,
            gridEntityName,
            renderGridMobile,
        } = this.state;

        const {
            data,
            selectionMode,
            cardAction,
            loadingData,
            hideLegend,
            gridKeys,
            subLabel,
            customEmptyMessage,
            onRefresh,
            user: {
                settings: {hidePricing},
            },
            isVisualFormulary,
        } = this.props;

        let tab = tabCounts.filter((x) => x.id === currentTab)[0];
        let showEmptyMessage = (tab || {}).count === 0;
        let gridItems = this.getGridItems();
        let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];

        if (!gridKey && data.length === 0) {
            showEmptyMessage = true;
            gridKey = {tabDisplay: subLabel};
        }
        if (gridItems && gridItems.length > 0) {
            for (let i = 0; i < gridItems.length; i++) {
                let curr = gridItems[i];

                const urlsData = getTrackingNumbersUrlsData(curr.trackingNumbers, curr.lineItemId);
                curr.urlsData = urlsData;
            }
        }
        
        let showFormularyLegend = gridItems.some((x) => x.isFormularyApproval);
        let columns = hidePricing ? columnsCandidate.filter((x) => x.field !== 'price' && x.field !== 'extendedPrice' && x.field !== 'unitPrice' && x.field !== 'oemPrice') : columnsCandidate;

        if (isVisualFormulary) {             
            if (gridKey.id === 'expert') {                                
                let hiddenExpertColumns = ['manufacturerDisplay','modalityDisplay','categoryDisplay','reasonForRule'];
                columns = _.cloneDeep(columns);
                columns.filter(x => hiddenExpertColumns.includes(x.field)).forEach(x => x.visible = false);
            } 
            
            showEmptyMessage = false;
        } 

        let displayColumns = columns
            .filter(
                (c) =>
                    c.visible === true &&
                    (c.allowedTabs == null ||
                        _.find(c.allowedTabs, (o) => {
                            return o == currentTab;
                        }) != undefined)
            )
            .map((col, i) => (
                <Column
                    visible={col.visible}
                    className={col.template === 'checkMark' || col.template === 'switch' ? 'preformatted no-click-cell' : 'preformatted'}
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable={col.sortable}
                    sortFunction={(e) => this.onSort(e, col.type)}
                    body={col.template ? this.columnTemplate : null}
                    style={this.columnStyle(col)}
                />
            ));

        if (selectionMode === 'multiple') {
            displayColumns.unshift(<Column selectionMode="multiple" style={{width: '3em'}} sortable={false} />);
        }

        // selection mode multiple shows checkboxes but there's no way to toggle the disabled flag
        if (selectionMode === 'checkbox') {
            displayColumns.unshift(<Column body={this.renderCheckboxColumn} sortable={false} className="checkbox-column no-click-cell" />);
        }

        return (
            <div className={gridKeys.length === 0 ? 'custom-primereact full-row' : 'custom-primereact'} style={{width: renderGridMobile ? '100%' : ''}}>
                <Lg>{this.renderMOQBar(gridItems)}</Lg>
                <Lg>{this.renderSelectionBar(gridItems)}</Lg>
                {notification ? (
                    <div className="selection-bar">
                        <span>{notification}</span>
                        <span className="buttons">
                            <i className="fa fa-remove" onClick={() => this.setState({notification: ''})} />
                        </span>
                    </div>
                ) : null}
                {loadingData ? (
                    <div className="loading-data">
                        <div className="loader" />
                    </div>
                ) : null}
                {view === ViewType.Grid || showEmptyMessage ? (<>
                    <PhoneLandscape>
                        {showEmptyMessage && customEmptyMessage && !loadingData && <div className="empty-container">
                            <img src="/images/icon_empty-queue.svg" />
                            <div className="empty-1">{`${customEmptyMessage}`}</div>
                        </div>}
                        {showEmptyMessage && !customEmptyMessage && !loadingData ? (
                            <div className="empty-container">
                                <img src="/images/icon_empty-queue.svg" />
                                <div className="empty-1">{`No Active ${gridEntityName} in ${gridKey.tabDisplay}`}</div>
                                <div className="empty-2">{`Your currently have no active ${gridEntityName} in ${gridKey.tabDisplay}`}</div>
                            </div>
                        ) : (
                            <div className="card-view-wrapper">{gridItems.map((x) => this.renderCard(x))}</div>
                        )}
                    </PhoneLandscape>
                    <Lg>
                        <React.Fragment>
                            {showEmptyMessage && customEmptyMessage && !loadingData && <div className="empty-container">
                                <img src="/images/icon_empty-queue.svg" />
                                <div className="empty-1">{`${customEmptyMessage}`}</div>
                            </div>}
                            {!customEmptyMessage ? (<div>
                                {showEmptyMessage && !loadingData ? (
                                    <div className="empty-container">
                                        <img src="/images/icon_empty-queue.svg" />
                                        <div className="empty-1">{`No Active ${gridEntityName} in ${gridKey.tabDisplay}`}</div>
                                        <div className="empty-2">{`Your currently have no active ${gridEntityName} in ${gridKey.tabDisplay}`}</div>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {isVisualFormulary && <FormularyFacets facets={this.props.facets || []} selectedFacets={this.props.selectedFacets || []} onChange={this.props.onFormularyFacetChange} onClearFilters={this.props.onClearFilters} />}                                        
                                        <div className="grid-wrapper">
                                            <div className="grid-container">
                                                <DataTable
                                                    id="custom-grid"
                                                    value={gridItems}
                                                    selectionMode={selectionMode}
                                                    resizableColumns={true}
                                                    columnResizeMode="expand"
                                                    reorderableColumns={true}
                                                    rowClassName={this.rowClassName}
                                                    selection={checkboxItems}
                                                    paginator={false}
                                                    rows={selectedPageSize}
                                                    onSelectionChange={(e) => this.onSelectItem(e.data)}
                                                    onRowSelect={(e) => this.onRowSelect(e)}
                                                    onColReorder={(e) => this.onColReorder(e)}
                                                    responsive={true}
                                                    first={(currentPage - 1) * selectedPageSize}
                                                    scrollable={false}
                                                    autoLayout={true}
                                                    onColumnResizeEnd={(e, i) => this.onColumnResizeEnd(e, i)}
                                                >
                                                    {displayColumns}
                                                </DataTable>
                                            </div>
                                            <GridPagination
                                                displayPages={10}
                                                totalRecords={gridItems.length}
                                                pageSize={selectedPageSize}
                                                onPageSizeChange={(data) => this.onPageSizeChange(data.value)}
                                                onSelect={(data) => this.onPageSelect(data.value)}
                                                currentPage={currentPage}
                                            />
                                            {!hideLegend ? this.renderLegend(showFormularyLegend) : ''}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>) : null}
                            {cardAction === CardActionType.AwaitingPO && !showEmptyMessage ? (
                                <div style={{float: 'right', paddingTop: '25px'}}>
                                    <SubmitPoForm purchaseLines={checkboxItems.map((x) => x.purchaseInfo)} onSubmit={() => this.onPoSubmit()} showPopup={false} />
                                </div>
                            ) : null}
                        </React.Fragment>
                    </Lg>
                </>) : (
                    <React.Fragment>
                        {isVisualFormulary && <FormularyFacets facets={this.props.facets || []} selectedFacets={this.props.selectedFacets || []} onChange={this.props.onFormularyFacetChange} onClearFilters={this.props.onClearFilters} />}
                        {gridItems.length > 0 && <div className="card-view-wrapper">{gridItems.map((x) => this.renderCard(x))}</div>}
                        {isVisualFormulary && gridItems.length === 0 && 
                                <div className="card-view-wrapper" style={{'color': '#949494', 'fontWeight': '600', 'fontSize': '18px', 'textAlign': 'center', 'paddingTop': '50px'}}>No Matching Rules Found</div>
                        }
                    </React.Fragment>                      
                )}
            </div>);
    }

    renderToolbar = () => {
        const {requesterViewType, disableCardView, headerLink, location: {pathname}, isVisualFormulary, setOrdersPredefinedFilters} = this.props;
        const {showSearchBox, view, renderGridMobile} = this.state;
        let showToggle = this.checkToggle();
        let cardViewClass = 'icon-view-selector';
        cardViewClass += disableCardView ? ' disable' : ' selected';

        const searchBoxClasses = {
            'form-control': true,
            'form-control hidden-search-box': true,
            'is-visible': showSearchBox,
        };

        return (<div className="grid-toolbar">
            {renderGridMobile && isVisualFormulary && 
            <PhoneLandscape>
                <div className="formulary-filter" onClick={() => this.setState({showFormularyFacetsModal: true})}>Filter</div>
            </PhoneLandscape>}
            {showSearchBox ? null : (
                <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md view-selector-wrapper">
                    <span>View:</span>

                    {view === ViewType.Grid && (
                        <img
                            src="/images/icon_card-view.svg"
                            title={`Switch to Card View`}
                            alt={`Switch to Card View`}
                            onClick={() => this.onViewChange(ViewType.Card)}
                            className={cardViewClass}
                            style={{marginRight: '10px'}}
                        />
                    )}

                    {view === ViewType.Card && (
                        <img
                            src="/images/icon_grid-view.svg"
                            title={`Switch to Grid View`}
                            alt={`Switch to Grid View`}
                            onClick={() => this.onViewChange(ViewType.Grid)}
                            className={cardViewClass}
                        />
                    )}
                </div>
            )}
            {pathname === '/orders/history' ? <Lg>
                <div className="hidden-search-wrapper">
                    <input
                        type="search"
                        className={classNames(searchBoxClasses)}
                        onFocus={() => this.onSearchFocus()}
                        onBlur={(e) => this.onSearchBlur(e)}
                        onChange={(e) => this.onSearch(e)}
                        autoComplete="grid-search"
                    />
                    <i className="glyphicon glyphicon-search" />
                </div>
            </Lg> :
                <div className={`hidden-search-wrapper ${!showToggle ? 'hidden-search-wrapper--no-margin' : ''}`}>
                    <input
                        type="search"
                        className={classNames(searchBoxClasses)}
                        onFocus={() => this.onSearchFocus()}
                        onBlur={(e) => this.onSearchBlur(e)}
                        onChange={(e) => this.onSearch(e)}
                        autoComplete="grid-search"
                    />
                    <i className="glyphicon glyphicon-search" />
                </div>}
            {showToggle && (
                <SwitchCheckbox
                    label="Only Me"
                    className="ps-hidden-md ps-hidden-lg ps-hidden-xl auto-refresh-toggle"
                    value={requesterViewType === RequesterViewType.CurrentUser}
                    onChange={(checked) => {
                        const result = {
                            value: {
                                selection: checked ? 'Left' : 'Right',
                            },
                        };
                        this.handleRequesterToggle(checked, result);
                    }}
                />
            )}
            <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md context-menu-wrapper">
                <i className="fa fa fa-cog" />
                <div className="context-menu">
                    <div className="context-item" onClick={() => this.onShowColumnSelection()}>
                        <span>Show / Hide Columns</span>
                    </div>
                    <div className="context-item" onClick={() => this.onExport()}>
                        <span>Export To Excel</span>
                    </div>
                </div>
            </div>
        </div>);
    }

    renderHeader = () => {
        const {label, subLabel, headerLink, loadingData, requesterViewType, setOrdersPredefinedFilters, location: {pathname}} = this.props;
        const {currentTab, defaultTab, onOpsV2} = this.state;
        const showVerticalNav = (window.localStorage && localStorage.getItem('showVerticalNav')) || false;
        if (!loadingData && currentTab && defaultTab) this.sendLogTabClick(currentTab, true);

        const showToggle = this.checkToggle();

        return (<div className={`grid-header ${pathname === '/orders/history' ? 'grid-header--title' : 'grid-header--sticky'}`}>
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                {onOpsV2 || showVerticalNav ? null :
                    <>
                        <span className="grid-label ps-hidden-xs ps-hidden-sm ps-hidden-md">{label}</span>
                        <span className="grid-sub-label">{subLabel}</span>
                    </>
                }
                {headerLink ? (
                    headerLink.url === '/orders/history' ? (
                        <NavLink className="grid-header-link" to={headerLink.url} onClick={() => setOrdersPredefinedFilters([])}>
                            {headerLink.text}
                        </NavLink>
                    ) : (
                        <NavLink className="grid-header-link" to={headerLink.url}>
                            {headerLink.text}
                        </NavLink>
                    )
                ) : null}
                {showToggle ? (
                    <div className="ps-hidden-xs ps-hidden-sm" style={{marginLeft: '20px', marginTop: '.5rem'}}>
                        <SwitchCheckbox
                            label="Only Me"
                            value={requesterViewType === RequesterViewType.CurrentUser}
                            onChange={(checked) => {
                                const result = {
                                    value: {
                                        selection: checked ? 'Left' : 'Right',
                                    },
                                };
                                this.handleRequesterToggle(checked, result);
                            }}
                        />
                    </div>
                ) : null}
            </div>
            {this.renderToolbar()}
        </div>);
    }
}
